import React from 'react';
import Icon from './icon';

function IconAccountGradient(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon
      width="33"
      height="37"
      fill="none"
      viewBox="0 0 33 37"
      {...props}>
      <path
        d="M22.8281 20.75C25.4062 20.75 27.6094 21.6875 29.4375 23.5625C31.3125 25.3906 32.25 27.5938 32.25 30.1719V33.125C32.25 34.0625 31.9219 34.8594 31.2656 35.5156C30.6094 36.1719 29.8125 36.5 28.875 36.5H4.125C3.1875 36.5 2.39062 36.1719 1.73438 35.5156C1.07812 34.8594 0.75 34.0625 0.75 33.125V30.1719C0.75 27.5938 1.66406 25.3906 3.49219 23.5625C5.36719 21.6875 7.59375 20.75 10.1719 20.75C10.7812 20.75 11.6719 20.9375 12.8438 21.3125C14.0625 21.6875 15.2812 21.875 16.5 21.875C17.7188 21.875 18.9375 21.6875 20.1562 21.3125C21.375 20.9375 22.2656 20.75 22.8281 20.75ZM30 33.125V30.1719C30 28.2031 29.2969 26.5156 27.8906 25.1094C26.4844 23.7031 24.7969 23 22.8281 23C22.5469 23 21.75 23.1875 20.4375 23.5625C19.1719 23.9375 17.8594 24.125 16.5 24.125C15.1406 24.125 13.8047 23.9375 12.4922 23.5625C11.2266 23.1875 10.4531 23 10.1719 23C8.20312 23 6.51562 23.7031 5.10938 25.1094C3.70312 26.5156 3 28.2031 3 30.1719V33.125C3 33.4531 3.09375 33.7109 3.28125 33.8984C3.51562 34.1328 3.79688 34.25 4.125 34.25H28.875C29.2031 34.25 29.4609 34.1328 29.6484 33.8984C29.8828 33.7109 30 33.4531 30 33.125ZM22.8281 15.8984C21.0938 17.6328 18.9844 18.5 16.5 18.5C14.0156 18.5 11.8828 17.6328 10.1016 15.8984C8.36719 14.1172 7.5 11.9844 7.5 9.5C7.5 7.01562 8.36719 4.90625 10.1016 3.17188C11.8828 1.39062 14.0156 0.5 16.5 0.5C18.9844 0.5 21.0938 1.39062 22.8281 3.17188C24.6094 4.90625 25.5 7.01562 25.5 9.5C25.5 11.9844 24.6094 14.1172 22.8281 15.8984ZM21.2812 4.71875C19.9688 3.40625 18.375 2.75 16.5 2.75C14.625 2.75 13.0312 3.40625 11.7188 4.71875C10.4062 6.03125 9.75 7.625 9.75 9.5C9.75 11.375 10.4062 12.9688 11.7188 14.2812C13.0312 15.5938 14.625 16.25 16.5 16.25C18.375 16.25 19.9688 15.5938 21.2812 14.2812C22.5938 12.9688 23.25 11.375 23.25 9.5C23.25 7.625 22.5938 6.03125 21.2812 4.71875Z"
        fill="url(#paint0_linear_59_6097)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_59_6097"
          x1="-7.40438"
          y1="-36.6835"
          x2="36.7273"
          y2="-36.6445"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0104167" stopColor="#FD880F" />
          <stop offset="0.489987" stopColor="#E93322" />
          <stop offset="1" stopColor="#9E1FCD" />
        </linearGradient>
      </defs>
    </Icon>
  );
}

export default IconAccountGradient;
