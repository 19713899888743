import React, { useRef, useState } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../../services/auth.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../component/global/button/Button";
import RequiredFieldWhite from "../../component/global/fileds/requiredFieldWhite";
import DesktopAnimation from "../../component/global/animation/desktopAnimation";
import MobileAnimation from "../../component/global/animation/mobileAnimation";

let passwordValue = "";
const vpassword = (value) => {
  passwordValue = value;
  if (value.length < 6) {
    return (
      <div className="text-white absolute" role="alert">
        The password must be at least 6 characters.
      </div>
    );
  }
};
const vpasswordConfirm = (value) => {
  if (value !== passwordValue) {
    return (
      <div className="text-white absolute" role="alert">
        The passwords are not matching.
      </div>
    );
  }
};
const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  searchParams.get("token");
  const form = useRef();
  const checkBtn = useRef();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordConfirmShown, setPasswordConfirmShown] = useState(false);
  const [loading] = useState(false);
  const [message, setMessage] = useState();

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  const onChangePasswordConfirmation = (e) => {
    const passwordConfirm = e.target.value;
    setPasswordConfirmation(passwordConfirm);
  };
  const handleForm = (e) => {
    e.preventDefault();
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      AuthService.saveNewPassword(
        searchParams.get("token"),
        password,
        passwordConfirmation
      ).then(
        (response) => {
          if (!response.data.error) {
            navigate("/login");
          } else {
            setMessage(Object.values(response.data.error));
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  return (
    <>
      <div className="container overflow-hidden w-full mx-auto px-5 text-center login-page flex md:items-center md:justify-center">
        <div className="mobile">
          <MobileAnimation />
        </div>
        <div className=" wrapper">
          <DesktopAnimation />
        </div>
        <div className="w-full flex flex-col mt-12 md:mt-0 ">
          <Form
            onSubmit={handleForm}
            ref={form}
            className="w-full mt-20 flex-col md:items-center md:justify-center mb-6"
          >
            <div className="md:w-1/3  mx-auto">
              <h3 className="uppercase text-2xl text-white font-bold mb-8">
                new password
              </h3>

              <div className="">
                <fieldset className="border border-white my-5">
                  <legend className="text-left ml-4 px-3 text-white uppercase">
                    {" "}
                    create new password
                  </legend>
                  <div className="relative">
                    <Input
                      type={passwordShown ? "text" : "password"}
                      className=" form-control custom-input m-1 text-white"
                      name="password"
                      value={password}
                      onChange={onChangePassword}
                      validations={[RequiredFieldWhite, vpassword]}
                    />{" "}
                    <FontAwesomeIcon
                      icon={passwordShown ? solid("eye") : solid("eye-slash")}
                      color="white"
                      className="absolute right-4 inset-y-1/4"
                      onClick={() => {
                        setPasswordShown(!passwordShown);
                      }}
                    />
                  </div>
                </fieldset>
              </div>
              <div className="mb-6">
                <fieldset className="border border-white">
                  <legend className="text-left ml-4 px-3 text-white uppercase">
                    confirm your Password
                  </legend>
                  <div className="relative">
                    <Input
                      type={passwordConfirmShown ? "text" : "password"}
                      className=" form-control custom-input  m-2 text-white"
                      name="password_confirmation"
                      value={passwordConfirmation}
                      onChange={onChangePasswordConfirmation}
                      validations={[RequiredFieldWhite, vpasswordConfirm]}
                    />
                    <FontAwesomeIcon
                      icon={
                        passwordConfirmShown ? solid("eye") : solid("eye-slash")
                      }
                      className="absolute right-4 inset-y-1/4"
                      color="white"
                      onClick={() => {
                        setPasswordConfirmShown(!passwordConfirmShown);
                      }}
                    />
                  </div>
                </fieldset>
              </div>
              <Button
                className="btn btn-primary btn-block"
                disabled={loading}
                text="update"
                class="small"
              ></Button>
              {message && (
                <div className="form-group">
                  <div
                    className="text-white uppercase my-4 font-bold w-full text-lg"
                    role="alert"
                  >
                    {message}
                  </div>
                </div>
              )}
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </div>
          </Form>
          <div className="flex flex-col items-center"></div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
