import React from "react";
import { USStates } from "./USStates";
import Select, { SingleValue, StylesConfig } from "react-select";

const selectCustomStyles: StylesConfig = {
  container: (provided) => ({
    ...provided,
    border: "none",
    color: "black",
    fontSize: "16px",
    borderColor: "transparent",
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 6,
    paddingRight: 8,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "black",
  }),
  control: (provided) => ({
    ...provided,
    minHeight: 32,
    border: "none",
    boxShadow: "none",
    background: "transparent",
    borderColor: "transparent",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0px 3px",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  menuList: (provided) => ({
    ...provided,
    background: "#f4f4f4",
    fontFamily: '"Museo Sans", sans-serif',
  }),
};

interface Props {
  initialValue?: string;
  onChange: (label: string) => void;
}

function StatesAutocomplete(props: Props) {
  const handleChange = (newValue: SingleValue<any>) => {
    setSelected(newValue);
    onChange(newValue?.label || "");
  };

  const getSelectedOption = (label: string) => {
    const index = USStates.findIndex((state) => state.label === label);
    if (index < 0) return null;

    return USStates[index];
  };

  const { onChange, initialValue = "" } = props;
  const [selected, setSelected] = React.useState(
    getSelectedOption(initialValue)
  );

  return (
    <Select
      isSearchable
      placeholder=""
      value={selected}
      options={USStates}
      onChange={handleChange}
      styles={selectCustomStyles}
    />
  );
}

export default StatesAutocomplete;
