import React from "react";
import Icon from "./icon";

function IconArrowLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10 19l-7-7m0 0l7-7m-7 7h18"
      />
    </Icon>
  );
}

export default IconArrowLeft;
