import { CartAddType, CartProductPricesTiersType, CartStateType, CartType, mapCartAdd } from "../cart/types";

export interface ProductVariantType {
    varianttype: {
        id: number,
        name: string,
        variant: {
            id: number,
            name: string,
        },
    };
}

export interface ProductTierType {
    min: number;
    max: number;
    eta: string;
    price: string;
    estimatedDeliveryDate?: string
}

export interface ProductPriceType {
    slug: string;
    title: string;
    tiers: ProductTierType[];
}

export interface ProductType {
    id: number;
    pdf: string;
    name: string;
    slug: string;
    size: string;
    uom: string;
    gallery: string[];
    description: string;
    cover_image: string;
    prices: ProductPriceType[];
    variants: ProductVariantType[];
}

export interface LinksType {
    first?: string
    last?: string
    next?: string
    prev?: string
}

export interface MetaLinkType {
    url: null, label: "&laquo; Previous", active: false
}

export interface MetaType {
    to: number
    from: number
    path: string
    total: number
    per_page: number
    last_page: number
    current_page: number
    links: MetaLinkType[]
}

export interface ProductListItemType {
    id: number
    uom: string
    name: string
    size: string
    slug: string
    cover_image: string
    variants: ProductVariantType[]
}

export interface ProductListType {
    meta: MetaType,
    links: LinksType,
    data: ProductListItemType[],
}


export type ProductQuantities = { [key: string]: number }

export const mapProductQuantities = (product: ProductType, cartState: CartStateType): ProductQuantities => {
    const quantities: ProductQuantities = {}
    const index = cartState.uiCartItems.findIndex(cartItem => cartItem.product_id === product.id)

    if (index < 0) {
        product.prices.forEach(price => { quantities[price.slug] = 0; })
    } else {
        const cartItem = cartState.uiCartItems[index];

        product.prices.forEach(price => {
            quantities[price.slug] = cartItem.package_types.find(pcg => pcg.type === price.slug)?.quantity || 0;
        })
    }

    return quantities
}

export const mapProduct = (product?: ProductType): ProductType => {
    if (!product) {
        return {
            id: -1,
            pdf: '',
            name: '',
            uom: '',
            slug: '',
            size: '',
            prices: [],
            gallery: [],
            variants: [],
            description: '',
            cover_image: '',
        }
    }

    return product;
};

export const mapUpdateCartFromProduct = (product: ProductType, cart: CartType, quantities: ProductQuantities): CartAddType => {
    const addToCart: CartAddType = mapCartAdd(cart)

    const productIndex = addToCart.cart_items.findIndex(cartItem => cartItem.product_id === product.id);

    if (productIndex < 0) {
        addToCart.cart_items.push({
            product_id: product.id,
            product_name: product.name,
            cover_image: product.cover_image,
            package_types: product.prices.map(price => ({
                type: price.slug,
                quantity: quantities[price.slug]
            })).filter(pcg => pcg.quantity > 0),
        })
    } else {
        addToCart.cart_items[productIndex] = {
            product_id: product.id,
            product_name: product.name,
            cover_image: product.cover_image,
            package_types: product.prices.map(price => ({
                type: price.slug,
                quantity: quantities[price.slug]
            })).filter(pcg => pcg.quantity > 0),
        }
    }

    return addToCart
}

export const getTopQuantity = (tiers: CartProductPricesTiersType[]) => {
    const values = tiers.map((tier) => tier.max);
    return Math.max(...values);
};