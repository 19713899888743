import React, { useEffect, useState } from "react";
import UserService from "../../../services/user.service";
import OrderDetails from "./OrderDetails";
import Loading from "../../../component/global/loading/loading";
import Button from "../../../component/global/button/Button";
import IconArrowLeftVariant from "../../../component/global/icons/iconArrowLeftVariant";

const Orders = (props) => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  useEffect(() => {
    UserService.getOrders().then((response) => {
      setOrders(response.data.data);
      setIsLoading(false);
      setLastPage(response.data.meta.last_page);
    });
  }, []);

  const queryUp = () => {
    setIsLoading(true);
    UserService.paginateOrders(page + 1).then((res) => {
      setOrders(res.data.data);
      setIsLoading(false);
      setPage(page + 1);
    });
  };
  const queryDown = () => {
    setIsLoading(true);
    UserService.paginateOrders(page - 1).then((res) => {
      setOrders(res.data.data);
      setIsLoading(false);
      setPage(page - 1);
    });
  };

  const getOrders = () => {
    return orders.map((order) => (
      <OrderDetails key={order.order_id} order={order} />
    ));
  };

  return (
    <div className="mt-5 md:mt-20">
      <h3 className="font-bold text-xl uppercase pb-10 border-b hidden md:block">
        orders
      </h3>
      <h3
        className="font-bold text-xl uppercase pb-6 pt-2 border-b text-[#9E1FCD] flex md:hidden items-center gap-3 cursor-pointer"
        onClick={() => {
          props.setActiveView("landing");
        }}
      >
        <IconArrowLeftVariant style={{ color: "#9E1FCD" }} />
        orders
      </h3>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="mb-5">
          <div>{getOrders()}</div>
          <div className="flex justify-between md:justify-start gap-10 mt-10 pb-5 md:pb-0">
            {page !== 1 && (
              <Button
                text="previous"
                class="ghost-sm"
                click={() => {
                  queryDown();
                }}
              />
            )}
            {page !== lastPage && (
              <Button
                text="next"
                class="ghost-sm"
                click={() => {
                  queryUp();
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Orders;
