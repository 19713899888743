import React from "react";
import Header from "../app/header/header";
import Home from "../../Containers/Home/Home";
import ProtectedRoute from "../global/auth/protectedRoute";

function HomePage() {
  return (
    <ProtectedRoute
      component={
        <>
          <Header />
          <Home />
        </>
      }
    />
  );
}

export default HomePage;
