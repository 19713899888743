import React from "react";
import style from "./product.module.scss";
import { useCart } from "../../../store/cart";
import Loading from "../../global/loading/loading";
import { Navigate, useParams } from "react-router-dom";
import { useGetProductQuery } from "../../../services/product.service";
import DetailsSlider from "../../../Containers/Product/DetailsSlider/DetailsSlider";
import {
  useAddToCartQuery,
  useModifyCartQuery,
} from "../../../services/cart.service";
import {
  mapProduct,
  ProductType,
  getTopQuantity,
  ProductPriceType,
  ProductQuantities,
  mapProductQuantities,
  mapUpdateCartFromProduct,
} from "./types";
import { CartStateType } from "../cart/types";
import ProductSwiper from "./swiper/productSwiper";
import ToggleSwitch from "./toggle-switch/toggleSwitch";
import BackButton from "./buttons/backButton";
import Button from "../../global/button/Button";
import { formatEstimatedDeliveryDate } from "../../../libs/date";

const ProductDetail = () => {
  const params = useParams();
  const cartState = useCart();
  const productSlug = params.productSlug || "";

  const { isFetching, data, isError } = useGetProductQuery(productSlug);

  if (!isFetching && (isError || !data)) {
    // TODO error message or page
    return <Navigate to="/" />;
  }

  const product = mapProduct(data);
  const quantities = mapProductQuantities(product, cartState);

  return (
    <div className="px-5 md:px-12">
      {isFetching ? (
        <Loading />
      ) : (
        <>
          <BackButton />
          <div className="flex flex-col md:grid md:grid-cols-1 lg:grid-cols-3">
            <div className="col-span-2 w-3/4 mx-auto">
              <ProductSwiper images={product.gallery} desktopImageCount={1} />
            </div>
            <ProductDetailSideComponent
              cartState={cartState}
              product={product}
              defaultQuantities={quantities}
            />
            <div
              className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-hidden bg-white"
              aria-labelledby="exampleModalFullscreenLabel"
              id="exampleModalFullscreen"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              aria-hidden="true"
              tabIndex={-1}
            >
              <div className="modal-dialog w-full relative pointer-events-none">
                <div className="modal-content border-none relative flex flex-col h-4/5 m-5 md:m-5 pointer-events-auto bg-gray-200">
                  <div className="modal-header flex flex-shrink-0 relative items-center justify-between p-4  rounded-t-md">
                    <button
                      type="button"
                      className="btn-close box-content w-12 h-12 p-1 text-black text-3xl border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body min-h-[650px] md:min-h-[900px]">
                    <DetailsSlider images={product.gallery} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProductDetail;

interface ProductDetailSideComponentProps {
  product: ProductType;
  cartState: CartStateType;
  defaultQuantities: ProductQuantities;
}

const ProductDetailSideComponent = (props: ProductDetailSideComponentProps) => {
  const { cartState, product, defaultQuantities } = props;
  const updateMutation = useModifyCartQuery(cartState.cart.id);
  const addMutation = useAddToCartQuery();

  const [quantities, setQuantities] = React.useState(defaultQuantities);

  const handleAddToCart = async (e: React.FormEvent) => {
    e.preventDefault();

    if (updateMutation.isLoading || addMutation.isLoading) {
      return;
    }

    const hasCart = cartState.cart.id > -1;

    const addTOCartState = mapUpdateCartFromProduct(
      product,
      cartState.cart,
      quantities
    );
    return hasCart
      ? updateMutation.mutateAsync(addTOCartState)
      : addMutation.mutateAsync(addTOCartState);
  };

  const handleChangePdf = (checked: boolean) => {
    setQuantities((quantities) => ({ ...quantities, pdf: checked ? 1 : 0 }));
  };

  const formatYmd = (estimatedDeliveryDate?: string) => {

    if (!estimatedDeliveryDate) {
      return formatEstimatedDeliveryDate(new Date());
    }

    return formatEstimatedDeliveryDate(new Date(estimatedDeliveryDate));
  };

  const getTierEtaPrice = (price: ProductPriceType) => {
    const currentQuantity = quantities[price.slug] ? quantities[price.slug] : 0;
    // Get corresponding tier
    let current = price.tiers.find(
      (tier) => currentQuantity >= tier.min && currentQuantity <= tier.max
    );

    if (!current) {
      current = price.tiers[0];
    }

    return { estimatedDeliveryDate: formatYmd(current.estimatedDeliveryDate), price: current.price };
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    price: ProductPriceType
  ) => {
    setQuantities((quantities) => ({
      ...quantities,
      [price.slug]: e.target.valueAsNumber || 0,
    }));
  };

  const addLoading = addMutation.isLoading || updateMutation.isLoading

  return (
    <div className="mt-5 lg:mt-0 mb-5">
      <h1>{product.name}</h1>
      <p>{product.description}</p>
      <form onSubmit={handleAddToCart}>
        {product.prices.map((price, priceIndex) => {
          const etaPrice = getTierEtaPrice(price);

          if (price.slug === "pdf") {
            return (
              <div
                className="detail_group border-b-2 border-gray-300 py-4"
                key={priceIndex}
              >
                <div className="flex justify-between items-center my-4">
                  <h2>PDF</h2>
                  <div className="block">
                    <ToggleSwitch
                      onChange={handleChangePdf}
                      small
                      id="1"
                      checked={Boolean(quantities["pdf"])}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 md:flex md:flex-wrap justify-between">
                  <p className="font-bold uppercase col-span-2 mb-3 md:mb-0">
                    Delivery date and pricing
                  </p>
                  <p className="">{formatYmd(price.tiers?.[0].eta)}</p>
                  <p className="font-bold text-right">
                    ${price.tiers?.[0].price.toLocaleString()} / ea
                  </p>
                </div>
              </div>
            );
          }

          return (
            <div
              className="detail_group border-b-2 border-gray-300 py-4"
              key={priceIndex}
            >
              <div className="flex justify-between items-center my-4">
                <h2>{price.title}</h2>
                <input
                  min="0"
                  type="number"
                  name="label_only"
                  value={quantities[price.slug]}
                  max={getTopQuantity(price.tiers)}
                  onChange={(e) => handleChange(e, price)}
                  className={"w-2/12 shrink-0 " + style.input}
                />
              </div>
              <div className="grid grid-cols-2 md:flex md:flex-wrap justify-between">
                <p className="font-bold uppercase col-span-2">
                  Delivery date and pricing
                </p>
                <p className="">{etaPrice.estimatedDeliveryDate}</p>
                <p className="font-bold text-right">${etaPrice.price} / ea</p>
              </div>
            </div>
          );

        })}
        <div className="flex w-full justify-center mt-10 mb-5 md:mb-0">
          <Button text="add to cart" class="rainbow" loading={addLoading} />
        </div>
      </form>
    </div>
  );
};
