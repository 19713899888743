import React from 'react';
import Icon from './icon';

function IconAddressBook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon
      width="33"
      height="37"
      viewBox="0 0 33 37"
      fill="none" {...props}>
      <path
        d="M25.7812 11.75H13.9688C13.4062 11.75 13.125 11.4688 13.125 10.9062V10.3438C13.125 9.78125 13.4062 9.5 13.9688 9.5H25.7812C26.3438 9.5 26.625 9.78125 26.625 10.3438V10.9062C26.625 11.4688 26.3438 11.75 25.7812 11.75ZM26.625 15.4062C26.625 15.9688 26.3438 16.25 25.7812 16.25H13.9688C13.4062 16.25 13.125 15.9688 13.125 15.4062V14.8438C13.125 14.2812 13.4062 14 13.9688 14H25.7812C26.3438 14 26.625 14.2812 26.625 14.8438V15.4062ZM31.1953 34.25H31.4062C31.9688 34.25 32.25 34.5312 32.25 35.0938V35.6562C32.25 36.2188 31.9688 36.5 31.4062 36.5H6.375C4.82812 36.5 3.49219 35.9375 2.36719 34.8125C1.28906 33.7344 0.75 32.4219 0.75 30.875V6.125C0.75 4.57812 1.28906 3.26562 2.36719 2.1875C3.49219 1.0625 4.82812 0.5 6.375 0.5H30.5625C31.0312 0.5 31.4297 0.664062 31.7578 0.992188C32.0859 1.32031 32.25 1.71875 32.25 2.1875V28.0625C32.25 28.8125 31.8984 29.3281 31.1953 29.6094C30.8672 31.1094 30.8672 32.6562 31.1953 34.25ZM9.75 27.5H30V2.75H9.75V27.5ZM3 28.625C3.98438 27.875 5.10938 27.5 6.375 27.5H7.5V2.75H6.375C5.4375 2.75 4.64062 3.07812 3.98438 3.73438C3.32812 4.39062 3 5.1875 3 6.125V28.625ZM29.1562 34.25C28.9219 32.75 28.9219 31.25 29.1562 29.75H6.375C4.96875 29.75 3.96094 30.125 3.35156 30.875C2.78906 31.625 2.78906 32.375 3.35156 33.125C3.96094 33.875 4.96875 34.25 6.375 34.25H29.1562Z"
        fill="url(#paint0_linear_59_6092)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_59_6092"
          x1="-7.40438"
          y1="-36.6835"
          x2="36.7273"
          y2="-36.6445"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0104167" stopColor="#FD880F" />
          <stop offset="0.489987" stopColor="#E93322" />
          <stop offset="1" stopColor="#9E1FCD" />
        </linearGradient>
      </defs>
    </Icon>
  );
}

export default IconAddressBook;
