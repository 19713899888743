import React from 'react';
import Icon from './icon';

function IconCheckOutline(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon
      width="13"
      height="13"
      fill="none"
      viewBox="0 0 13 13"
      {...props}>

      <path
        d="M6.5 0C7.65323 0 8.75403 0.314516 9.75 0.891129C10.746 1.46774 11.5323 2.25403 12.1089 3.25C12.6855 4.24597 13 5.34677 13 6.5C13 7.67944 12.6855 8.75403 12.1089 9.75C11.5323 10.746 10.746 11.5585 9.75 12.1351C8.75403 12.7117 7.65323 13 6.5 13C5.32056 13 4.24597 12.7117 3.25 12.1351C2.25403 11.5585 1.44153 10.746 0.864919 9.75C0.288306 8.75403 0 7.67944 0 6.5C0 5.34677 0.288306 4.24597 0.864919 3.25C1.44153 2.25403 2.25403 1.46774 3.25 0.891129C4.24597 0.314516 5.32056 0 6.5 0ZM6.5 1.25806C5.55645 1.25806 4.66532 1.49395 3.87903 1.96573C3.06653 2.4375 2.4375 3.09274 1.96573 3.87903C1.49395 4.69153 1.25806 5.55645 1.25806 6.5C1.25806 7.44355 1.49395 8.33468 1.96573 9.12097C2.4375 9.93347 3.06653 10.5625 3.87903 11.0343C4.66532 11.506 5.55645 11.7419 6.5 11.7419C7.44355 11.7419 8.30847 11.506 9.12097 11.0343C9.90726 10.5625 10.5625 9.93347 11.0343 9.12097C11.506 8.33468 11.7419 7.44355 11.7419 6.5C11.7419 5.55645 11.506 4.69153 11.0343 3.87903C10.5625 3.09274 9.90726 2.4375 9.12097 1.96573C8.30847 1.49395 7.44355 1.25806 6.5 1.25806ZM10.1694 4.66532C10.2218 4.74395 10.2742 4.82258 10.2742 4.90121C10.2742 5.00605 10.2218 5.05847 10.1694 5.11089L5.66129 9.59274C5.58266 9.67137 5.50403 9.69758 5.4254 9.69758C5.32056 9.69758 5.26815 9.67137 5.21573 9.59274L2.83065 7.20766C2.75202 7.15524 2.72581 7.07661 2.72581 6.97177C2.72581 6.89315 2.75202 6.81452 2.83065 6.7621L3.43347 6.15927C3.48589 6.10685 3.53831 6.08065 3.64315 6.08065C3.72177 6.08065 3.8004 6.10685 3.87903 6.15927L5.4254 7.75806L9.14718 4.0625C9.1996 4.01008 9.25202 3.98387 9.35686 3.98387C9.43548 3.98387 9.51411 4.03629 9.59274 4.08871L10.1694 4.66532Z"
        fill="currentColor"
      />
    </Icon>

  );
}

export default IconCheckOutline;
