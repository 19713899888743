import React, { useEffect, useState } from "react";
import UserService from "../../../services/user.service";
import EventBus from "../../../common/EventBus";
import classes from "./ProductFilter.module.scss";
import IconArrowUp from "../../../component/global/icons/iconArrowUp";
import IconArrowDown from "../../../component/global/icons/iconArrowDown";

const ProductFilter = (props) => {
  const initialState = props.filters;
  const [isActive, setIsActive] = useState(false);
  const [activeFilterArray] = useState([]);
  let [activeFilters, setActiveFilters] = useState(0);
  let [counter, setCounter] = useState(0);
  let [filter, setFilter] = useState({});
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (window.innerWidth >= 768) {
      setIsActive(true);
      window.addEventListener("resize", () => {
        setIsActive(false);
        if (window.innerWidth >= 768) {
          setIsActive(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    let array = initialState.keys;
    if (array) setActiveFilters(array.filter((x) => x === true).length);
  }, [activeFilterArray]);

  useEffect(() => {
    UserService.queryProducts(filter).then(
      (response) => {
        props.setProducts(response.data.data);
        props.setTotal(response.data.meta.total);
        props.setFilter(filter);
        props.setLimit(8);
      },
      (error) => {
        console.log(error);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, [activeFilters]);

  const resetFilter = () => {
    setFilter({});
    props.setFilter({});
    setSearch("");
    getFilterGroups();
    setActiveFilters(0);
    setCounter((counter += 1));
  };
  const getFilters = (group) => {
    return (
      <div className="flex flex-row flex-wrap" key={"groupwrapper" + group}>
        {Object.values(props.filters[group]).map((x) => (
          <div key={x.name} className={classes["filter__container"]}>
            <input
              type="checkbox"
              className={classes["filter"]}
              key={x.name + x.id}
              id={x.name}
              name={group}
              value={x.id}
              checked={
                (x.is_attribute &&
                  "attributes" in filter &&
                  group in filter.attributes &&
                  filter["attributes"][group].indexOf(parseFloat(x.id)) > -1) ||
                (!x.is_attribute &&
                  group in filter &&
                  filter[group].indexOf(x.id) > -1)
              }
              onChange={(e) => {
                let filterArray = [];
                let local = filter;
                if (e.target.checked) {
                  setActiveFilters((activeFilters += 1));

                  if (x.is_attribute) {
                    if (!("attributes" in local)) {
                      local["attributes"] = {};
                    }
                    if (!(e.target.name in local.attributes)) {
                      local["attributes"][e.target.name] = [
                        parseFloat(e.target.value),
                      ];
                    } else {
                      filterArray = local["attributes"][e.target.name];
                      filterArray.push(parseFloat(e.target.value));
                      local["attributes"][e.target.name] = filterArray;
                    }
                  } else {
                    if (!(e.target.name in local)) {
                      local[e.target.name] = [parseFloat(e.target.value)];
                    } else {
                      filterArray = local[e.target.name];
                      filterArray.push(parseFloat(e.target.value));
                      local[e.target.name] = filterArray;
                    }
                  }
                } else {
                  setActiveFilters((activeFilters -= 1));
                  let index = 0;
                  if (x.is_attribute) {
                    index = local["attributes"][e.target.name].indexOf(
                      parseFloat(x.id)
                    );
                    if (index > -1) {
                      local["attributes"][e.target.name].splice(index, 1);
                    }
                    if (local["attributes"][e.target.name].length === 0) {
                      delete local.attributes[e.target.name];
                    }
                    if (Object.keys(local["attributes"]).length === 0) {
                      delete local.attributes;
                    }
                  } else {
                    index = local[e.target.name].indexOf(parseFloat(x.id));
                    if (index > -1) {
                      local[e.target.name].splice(index, 1);
                    }
                    if (local[e.target.name].length === 0) {
                      delete local[e.target.name];
                    }
                  }
                  setFilter(local);
                }
              }}
            />
            <label key={"label" + x.name} htmlFor={x.name}>
              {x.name}
            </label>
          </div>
        ))}
      </div>
    );
  };

  const getFilterGroups = () => {
    return Object.keys(props.filters).map((x) => (
      <div className="filter__group" key={"group" + x + counter}>
        <h2 className="filter__title" key={"title" + x}>
          {x}
        </h2>
        {getFilters(x)}
      </div>
    ));
  };

  return (
    <div className="accordion-item">
      <div
        className={
          (isActive && "border-b border-gray-300") +
          " md:hidden flex justify-between py-5"
        }
      >
        <button className="accordion-title font-bold">
          <h2 onClick={() => setIsActive(!isActive)}>
            {activeFilters > 0 ? "Filters (" + activeFilters + ")" : "Filters"}
          </h2>
        </button>
        {isActive ? (
          <div className="flex items-center justify-between">
            <button
              className="filter-reset mr-4 text-purple-700 font-bold text-xl"
              onClick={() => {
                resetFilter();
              }}
            >
              CLEAR ALL
            </button>
            <IconArrowUp
              fill="none"
              strokeWidth={2}
              viewBox="0 0 24 24"
              className="h-6 w-6"
              stroke="currentColor"
              onClick={() => setIsActive(!isActive)}
            />
          </div>
        ) : (
          <IconArrowDown
            fill="none"
            strokeWidth={2}
            className="h-6 w-6"
            viewBox="0 0 24 24"
            stroke="currentColor"
            onClick={() => setIsActive(!isActive)}
          />
        )}
      </div>
      <div className="hidden md:flex justify-between border-b border-gray-300 py-5">
        <button className="accordion-title font-bold">
          <h2>
            {activeFilters > 0 ? "Filters (" + activeFilters + ")" : "Filters"}
          </h2>
        </button>
        {isActive && (
          <div className="flex items-center justify-between">
            <button
              className="filter-reset mr-4 text-purple-700 font-bold text-lg"
              onClick={() => {
                resetFilter();
              }}
            >
              CLEAR ALL
            </button>
          </div>
        )}
      </div>
      {isActive && (
        <div className="accordion-content">
          <div className="filter flex flex-col">
            <div className="items-center justify-center py-6  border-b border-gray-500 w-full flex">
              <fieldset className="border-2 border-gray-500 w-full">
                <legend className=" text-left ml-4 px-3 font-bold uppercase">
                  Search
                </legend>
                <div className="relative">
                  <input
                    type="text"
                    className="form-control custom-input m-1 "
                    name="phone"
                    value={search}
                    onChange={(e) => {
                      let local = { ...filter, search: e.target.value };
                      setSearch(e.target.value);
                      setFilter(local);
                      setActiveFilters(activeFilters + 1);
                      setActiveFilters(activeFilters - 1);
                    }}
                  />
                </div>
              </fieldset>
            </div>
            {getFilterGroups()}
          </div>
        </div>
      )}
    </div>
  );
};
export default ProductFilter;
