import React from "react";
import classes from "./ThankYou.module.scss";
import UserService from "../../../services/user.service";
import Button from "../../../component/global/button/Button";
import MobileAnimation from "../../../component/global/animation/mobileAnimation";
import DesktopAnimation from "../../../component/global/animation/desktopAnimation";
import IconCheckSuccess from "../../../component/global/icons/iconCheckSuccess";

const ThankYou = (props) => {
  return (
    <div
      className={
        classes["page"] +
        " flex items-center justify-center px-5 md:px-0 mt-16 md:mt-0"
      }
    >
      <div className="">
        <div className={classes["mobile"]}>
          <MobileAnimation />
        </div>
        <div className={classes["wrapper"]}>
          <DesktopAnimation />
        </div>
        <div className="text-xl alert text-white ">
          <div className="flex flex-col items-center text-white">
            <IconCheckSuccess className="mb-5" />
            <h3 className="text-white uppercase font-bold mb-8">
              Thank you for your order
            </h3>
            <div className="text-sm text-center text-white mb-8 w-2/3">
              We're looking forward to working together. We know that things
              change, so feel free to give us a call @ 513-221-6863 or a chat
              via this app.
            </div>
            <Button
              text="order summary"
              click={() => {
                UserService.getOrder(props.orderID).then((response) => {
                  props.setOrder(response.data.data);
                  props.setActiveView("summary");
                });
              }}
              class="small"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
