import React, { useRef, useState } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../../services/auth.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Button from "../../component/global/button/Button";
import RequiredFieldWhite from "../../component/global/fileds/requiredFieldWhite";
import RequiredEmailWhite from "../../component/global/fileds/requiredEmailWhite";
import { useNavigate } from "react-router-dom";
import DesktopAnimation from "../../component/global/animation/desktopAnimation";
import MobileAnimation from "../../component/global/animation/mobileAnimation";

const ForgotPassword = () => {
  const form = useRef();
  const checkBtn = useRef();
  const navigate = useNavigate();
  const [loading] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState();
  const [success, setSuccess] = useState(false);

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };
  const handleForm = (e) => {
    e.preventDefault();
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      AuthService.forget(email).then(
        (response) => {
          if (!response.data.error) {
            setSuccess(true);
          } else {
            setMessage(Object.values(response.data.error));
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };
  const logIn = () => {
    navigate("/login");
  };
  return (
    <>
      <div className="container overflow-hidden w-full mx-auto px-5 md:px-10 text-center login-page flex md:items-center md:justify-center">
        <div className="mobile">
          <MobileAnimation />
        </div>
        <div className=" wrapper">
          <DesktopAnimation />
        </div>
        {success ? (
          <div className="flex flex-col items-center text-white md:w-2/3">
            <div className="flex items-center justify-center">
              <FontAwesomeIcon
                icon={solid("envelope")}
                className="p-7 bg-orange-400 rounded-full w-fit font-bold mb-6 h-10 w-10"
              />
            </div>

            <h3 className="text-white mb-16 text-2xl font-bold uppercase">
              Check your email!
            </h3>
            <div className="text-base text-center text-white">
              Please check the email address you've provided for instruction. If
              you are experiencing trouble, please contact 3D Color at
              513-221-6863 or Chat here
            </div>
          </div>
        ) : (
          <div className="w-full flex flex-col mt-12 md:mt-0 ">
            <Form
              onSubmit={handleForm}
              ref={form}
              className="w-full mt-20 flex-col md:items-center md:justify-center mb-6"
            >
              <div className="md:w-1/3  mx-auto">
                <h3 className="uppercase text-2xl text-white font-bold mb-8">
                  forgot password
                </h3>
                <div className="text-base mb-8 text-white">
                  Please enter your email address below and we will send you a
                  link to reset your password.
                </div>
                <div className="items-center justify-center w-full mb-6">
                  <fieldset className="border border-white">
                    <legend className="text-left ml-4 px-3 text-white uppercase">
                      Email address
                    </legend>
                    <Input
                      type=" text"
                      className=" form-control custom-input m-2 text-white"
                      name="email"
                      value={email}
                      onChange={onChangeEmail}
                      validations={[RequiredFieldWhite, RequiredEmailWhite]}
                    />
                  </fieldset>
                </div>
                <Button
                  className="btn btn-primary btn-block"
                  disabled={loading}
                  text="continue"
                  class="small"
                ></Button>
                {message && (
                  <div className="form-group">
                    <div
                      className="text-white uppercase my-4 font-bold w-full text-lg"
                      role="alert"
                    >
                      {message}
                    </div>
                  </div>
                )}
                <CheckButton style={{ display: "none" }} ref={checkBtn} />
                <h4 className="text-white mt-6 text uppercase border-t border-[#ffffff40] pt-4">
                  back to{" "}
                  <strong onClick={logIn} className="cursor-pointer">
                    Log In
                  </strong>
                  .
                </h4>
              </div>
            </Form>
            <div className="flex flex-col items-center"></div>
          </div>
        )}
      </div>
    </>
  );
};

export default ForgotPassword;
