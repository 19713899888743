import { CartPackageType, CartType, UICartPackageType } from "../../component/app/cart/types"

export interface CartItemUpdateQuantityPayloadType {
  quantity: number,
  cartPackageType: UICartPackageType
}

export interface CartUpdateAction {
  type: "UPDATE_CART"
  payload: CartType
}

export interface CartAddItemAction {
  type: "ADD_CART_ITEM",
  payload: CartPackageType
}

export interface CartClearAction {
  type: "CLEAR_CART"
}

export interface CartItemUpdateQuantityAction {
  type: "UPDATE_CART_ITEM_QUANTITY",
  payload: CartItemUpdateQuantityPayloadType
}

export interface CartItemRemoveAction {
  type: "REMOVE_CART_ITEM",
  payload: UICartPackageType
}

export type CartActions = CartUpdateAction | CartClearAction | CartItemUpdateQuantityAction | CartItemRemoveAction | CartAddItemAction

export function updateCart(payload: CartType): CartActions {
  return { type: 'UPDATE_CART', payload };
}

export function addToCart(payload: CartPackageType): CartActions {
  return { type: 'ADD_CART_ITEM', payload };
}

export function clearCart(): CartActions {
  return { type: 'CLEAR_CART' };
}

export function updateCartItemQuantity(payload: CartItemUpdateQuantityPayloadType): CartActions {
  return { type: 'UPDATE_CART_ITEM_QUANTITY', payload };
}

export function removeCartItem(payload: UICartPackageType): CartActions {
  return { type: 'REMOVE_CART_ITEM', payload };
}

