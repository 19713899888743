import React, { useEffect, useState } from "react";
import UserService from "../../../services/user.service";
import Button from "../../../component/global/button/Button";
import { useNavigate } from "react-router-dom";

const AddressSelect = (props) => {
  const [address] = useState(props.addresses.at(-1));
  const navigate = useNavigate();

  useEffect(() => {
    props.setPhase(1);
  }, []);
  return (
    <div>
      <div className="mx-auto md:w-2/3 mt-8 px-5">
        <h3 className="uppercase text-center text-2xl font-bold mb-20">
          Delivery address
        </h3>
        <div className="flex w-full justify-between pb-10">
          <div className="flex flex-col text-left">
            <strong className="mb-8">
              {" "}
              {address.first_name} {address.last_name}
            </strong>
            <div>{address.company_name}</div>
            <div>
              {address.address} {address.address2}
            </div>
            <div>
              {address.city}, {address.state} {address.zipcode}
            </div>
          </div>
          <div
            className="flex flex-col  cursor-pointer"
            onClick={() => {
              props.setIsModalVisible(true);
            }}
          >
            <Button text="Modify or Add new" class="ghost-sm" />
          </div>
        </div>
        <div className="flex flex-col items-center md:flex-row md:justify-between gap-5">
          <Button
            text="back to cart"
            class="ghost"
            click={() => {
              navigate("/cart");
            }}
          />
          <Button
            text="continue"
            click={() => {
              UserService.setOrderAddress(props.orderID, address.id).then(
                (response) => {
                  props.setActiveView("dateSelect");
                }
              );
            }}
            class="rainbow"
          />
        </div>
      </div>
    </div>
  );
};

export default AddressSelect;
