import React, { useRef, useState } from "react";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import UserService from "../../../services/user.service";
import { clearCart } from "../../../store/actions/cart";
import { useAppDispatch } from "../../../store/store";
import StatesAutocomplete from "../../../component/global/autocomplete/statesAutocomplete";
import Loading from "../../../component/global/loading/loading";
import Button from "../../../component/global/button/Button";
import RequiredField from "../../../component/global/fileds/requiredField";
import { logout } from "../../../store/actions/auth";
import { clearAuth } from "../../../libs/storage/auth";
import IconArrowLeftVariant from "../../../component/global/icons/iconArrowLeftVariant";

let passwordValue = "";
const vpassword = (value) => {
  passwordValue = value;
  if (value.length < 6) {
    return (
      <div className="text-white absolute" role="alert">
        The password must be at least 6 characters.
      </div>
    );
  }
};
const vpasswordConfirm = (value) => {
  if (value !== passwordValue) {
    return (
      <div className="text-white absolute" role="alert">
        The passwords are not matching.
      </div>
    );
  }
};
const EditProfile = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const form2 = useRef();
  const checkBtn2 = useRef();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [fName, setFName] = useState(props.userData.first_name);
  const [lName, setLName] = useState(props.userData.last_name);
  const [address, setAddress] = useState(props.userData.address);
  const [address2, setAddress2] = useState(props.userData.address2);
  const [city, setCity] = useState(props.userData.city);
  const [state, setState] = useState(props.userData.state);
  const [zip, setZip] = useState(props.userData.zipcode);
  const [mobile, setMobile] = useState(props.userData.phone);
  const [oldPW, setOldPW] = useState("");
  const [newPW, setNewPw] = useState("");
  const [PWConfirmation, setPWConfirmation] = useState("");
  const [OldPWShown, setOldPWShown] = useState(false);
  const [newPWShown, setNewPWShown] = useState(false);
  const [passwordConfirmShown, setPasswordConfirmShown] = useState(false);
  const [, setSuccessful] = useState(false);
  const [pwModal, setPWModal] = useState(false);

  const onChangeFName = (e) => {
    setFName(e.target.value);
  };
  const onChangeLName = (e) => {
    setLName(e.target.value);
  };
  const onChangeAddress = (e) => {
    setAddress(e.target.value);
  };
  const onChangeAddress2 = (e) => {
    setAddress2(e.target.value);
  };
  const onChangeCity = (e) => {
    setCity(e.target.value);
  };
  const onChangeZip = (e) => {
    setZip(e.target.value);
  };
  const onChangeState = (value) => {
    setState(value);
  };
  const onChangeMobile = (e) => {
    setMobile(e.target.value);
  };
  const handleForm = (e) => {
    e.preventDefault();
    setSuccessful(false);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      setIsLoading(true);
      UserService.updateContact(
        fName,
        lName,
        address,
        address2,
        city,
        state,
        zip,
        mobile
      )
        .then(
          (response) => {},
          (error) => {
            // const message =
            //   (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          }
        )
        .then(() => {
          setSuccessful(true);
          UserService.getUserData().then(
            (response) => {
              props.setUserData(response.data.data);
            },
            (error) => {
              // const message =
              //   (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
          );
          setIsLoading(false);
        })
        .catch(() => {
          setSuccessful(false);
        });
    }
  };

  const updatePassword = (e) => {
    e.preventDefault();
    setSuccessful(false);
    form2.current.validateAll();
    if (checkBtn2.current.context._errors.length === 0) {
      UserService.updatePassword(oldPW, newPW, PWConfirmation).then(
        (response) => {
          setPWModal(true);
          clearAuth();
          dispatch(clearCart);
        },
        (error) => {
          // const message =
          //   (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        }
      );
    }
  };

  const onChangeOldPW = (e) => {
    setOldPW(e.target.value);
  };
  const onChangeNewPW = (e) => {
    setNewPw(e.target.value);
  };
  const onChangePWConfirmation = (e) => {
    setPWConfirmation(e.target.value);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="mt-5 md:mt-20">
      <h3 className="font-bold text-xl uppercase pb-10 hidden md:block">
        Your Contact info
      </h3>
      <h3
        className="font-bold text-xl uppercase pb-5 pt-2 text-[#9E1FCD] flex md:hidden items-center gap-3 cursor-pointer"
        onClick={() => {
          props.setActiveView("landing");
        }}
      >
        <IconArrowLeftVariant style={{ color: "#9E1FCD" }} />
        your contact info
      </h3>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col">
          <div className="text-xs uppercase">
            Please share with us your contact information in the event we need
            to contact you regarding a shipment.
          </div>
          <Form onSubmit={handleForm} ref={form} className="w-full ">
            <div className="md:grid md:grid-cols-2 gap-x-5">
              <div className="items-center justify-center w-full mt-6 mb-4">
                <fieldset className="border border-gray-500">
                  <legend className="text-left ml-4 px-3 uppercase">
                    First Name
                  </legend>
                  <Input
                    type="text"
                    className="form-control custom-input m-1 "
                    name="first_name"
                    value={fName}
                    onChange={onChangeFName}
                    validations={[RequiredField]}
                  />
                </fieldset>
              </div>
              <div className="items-center justify-center w-full mt-6 mb-4">
                <fieldset className="border border-gray-500">
                  <legend className="text-left ml-4 px-3 uppercase">
                    Last Name
                  </legend>
                  <Input
                    type="text"
                    className="form-control custom-input m-1 "
                    name="first_name"
                    value={lName}
                    onChange={onChangeLName}
                    validations={[RequiredField]}
                  />
                </fieldset>
              </div>
              <div className="items-center justify-center w-full mb-4">
                <fieldset className="border border-gray-500">
                  <legend className="text-left ml-4 px-3 uppercase">
                    Address
                  </legend>
                  <Input
                    type="text"
                    className="form-control custom-input m-1 "
                    name="first_name"
                    value={address}
                    onChange={onChangeAddress}
                    validations={[RequiredField]}
                  />
                </fieldset>
              </div>
              <div className="items-center justify-center w-full mb-4">
                <fieldset className="border border-gray-500">
                  <legend className="text-left ml-4 px-3 uppercase">
                    Address 2 <i className="lowercase">(optional)</i>
                  </legend>
                  <Input
                    type="text"
                    className="form-control custom-input m-1 "
                    name="first_name"
                    value={address2}
                    onChange={onChangeAddress2}
                  />
                </fieldset>
              </div>
              <div className="items-center justify-center w-full mb-6">
                <fieldset className="border border-gray-500">
                  <legend className="text-left ml-4 px-3  uppercase">
                    city
                  </legend>
                  <Input
                    type="text"
                    className="form-control custom-input m-1 "
                    name="city"
                    value={city}
                    onChange={onChangeCity}
                    validations={[RequiredField]}
                  />
                </fieldset>
              </div>
              <div className="items-center justify-center w-full mb-6">
                <fieldset className="border border-gray-500">
                  <legend className="text-left ml-4 px-3  uppercase">
                    State
                  </legend>
                  <StatesAutocomplete
                    initialValue={state}
                    onChange={onChangeState}
                  />
                  <Input
                    type="text"
                    hidden
                    name="state"
                    value={state}
                    validations={[RequiredField]}
                  />
                </fieldset>
              </div>
              <div className="items-center justify-center w-full mb-6">
                <fieldset className="border border-gray-500">
                  <legend className="text-left ml-4 px-3  uppercase">
                    zip code
                  </legend>
                  <Input
                    type="text"
                    className="form-control custom-input m-1 "
                    name="zip"
                    value={zip}
                    onChange={onChangeZip}
                    validations={[RequiredField]}
                  />
                </fieldset>
              </div>
              <div className="items-center justify-center w-full mb-6">
                <fieldset className="border border-gray-500">
                  <legend className="text-left ml-4 px-3  uppercase">
                    mobile <i className="lowercase">(optional)</i>
                  </legend>
                  <Input
                    type="text"
                    className="form-control custom-input m-1 "
                    name="phone"
                    value={mobile}
                    onChange={onChangeMobile}
                  />
                </fieldset>
              </div>
              <div className="flex justify-center md:justify-start w-full pb-5 border-b-2">
                <Button
                  class="rainbow"
                  loading={isLoading}
                  className="mt-2 md:mt-0"
                  text="update contact info"
                />
                <CheckButton style={{ display: " none" }} ref={checkBtn} />
              </div>
            </div>
          </Form>
          <div className="text-2xl font-bold uppercase my-6">
            change password
          </div>
          <div className="text-xs uppercase">
            IF YOU WISH TO CHANGE THE PASSWORD TO ACCESS YOUR ACCOUNT, PLEASE
            PROVIDE THE FOLLOWING INFORMATION:
          </div>
          <Form onSubmit={updatePassword} ref={form2} className="w-full ">
            <div className="md:grid md:grid-cols-2 gap-x-5">
              <div className="items-center justify-center w-full mt-6 mb-4">
                <fieldset className="border border-gray-500">
                  <legend className="text-left ml-4 px-3 uppercase">
                    current password
                  </legend>
                  <div className="relative">
                    <Input
                      type={OldPWShown ? "text" : "password"}
                      className="form-control custom-input m-2 "
                      name="first_name"
                      value={oldPW}
                      onChange={onChangeOldPW}
                    />
                    <FontAwesomeIcon
                      icon={OldPWShown ? solid("eye") : solid("eye-slash")}
                      className="absolute right-4 inset-y-1/4"
                      onClick={() => {
                        setOldPWShown(!OldPWShown);
                      }}
                    />
                  </div>
                </fieldset>
              </div>
              <div className="hidden md:block" />
              <div className="mb-6">
                <fieldset className="border  border-gray-500 ">
                  <legend className="text-left ml-4 px-3 uppercase">
                    new password
                  </legend>
                  <div className="relative">
                    <Input
                      type={newPWShown ? "text" : "password"}
                      className=" form-control custom-input m-2"
                      name="password"
                      value={newPW}
                      onChange={onChangeNewPW}
                      validations={[RequiredField, vpassword]}
                    />{" "}
                    <FontAwesomeIcon
                      icon={newPWShown ? solid("eye") : solid("eye-slash")}
                      className="absolute right-4 inset-y-1/4"
                      onClick={() => {
                        setNewPWShown(!newPWShown);
                      }}
                    />
                  </div>
                </fieldset>
              </div>
              <div className="mb-6">
                <fieldset className="border  border-gray-500">
                  <legend className="text-left ml-4 px-3  uppercase">
                    repeat Password
                  </legend>
                  <div className="relative">
                    <Input
                      type={passwordConfirmShown ? "text" : "password"}
                      className=" form-control custom-input  m-2 "
                      name="password_confirmation"
                      value={PWConfirmation}
                      onChange={onChangePWConfirmation}
                      validations={[RequiredField, vpasswordConfirm]}
                    />
                    <FontAwesomeIcon
                      icon={
                        passwordConfirmShown ? solid("eye") : solid("eye-slash")
                      }
                      className="absolute right-4 inset-y-1/4"
                      onClick={() => {
                        setPasswordConfirmShown(!passwordConfirmShown);
                      }}
                    />
                  </div>
                </fieldset>
              </div>

              <div className="flex justify-center md:justify-start w-full pb-5 border-b-2">
                <Button
                  text="update password"
                  class="rainbow"
                  className="mt-2 md:mt-0"
                />
                <CheckButton style={{ display: " none" }} ref={checkBtn2} />
              </div>
            </div>
          </Form>
          {pwModal && (
            <div
              tabIndex="-1"
              className="block overflow-y-auto w-full overflow-x-hidden fixed top-0 bottom-0 bg-gray-500/90 right-0 left-0 z-50 md:inset-0 h-modal flex justify-center items-center md:h-full"
            >
              <div className="relative md:p-8 w-full h-full md:h-auto md:flex items-center justify-center">
                <div className="relative bg-white h-full md:w-[700px] shadow-xl dark:bg-gray-700">
                  <div className="px-20 py-6 text-center">
                    <h3 className="mb-5 text-2xl uppercase font-bold font-normal dark:text-gray-400">
                      you have changed your password
                    </h3>

                    <div className="my-20 text-xl font-bold">
                      Please, log in again, with your new password !
                    </div>
                    <Button
                      text={"Log Out"}
                      class={"rainbow"}
                      click={handleLogout}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EditProfile;
