import React from 'react'

function AnimationLefttSide() {
    return (
        <svg width="400" height="1000" viewBox="0 0 400 827" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill="url(#paint0_linear_59_5455)">
                <animate attributeName="d" values="M 33.6198 78.7942C134.498 185.161 81.9856 228.181 51.8253 350.305C29.6834 439.962 51.9958 594.158 -76.3181 648.724C-204.632 703.291 -508.634 694.222 -571.316 538.896C-633.997 383.569 -641.515 320.848 -481.082 256.106C-320.649 191.364 -171.736 -151.985 33.6198 78.7942Z;
                                         M -128.983 14.471C3.88147 51.6745 -17.7611 111.79 16.8583 225.012C42.274 308.133 134.772 421.92 57.4717 527.973C-19.8291 634.027 -269.79 773.528 -395.446 678.325C-521.101 583.122 -557.467 536.085 -459.135 406.299C-360.802 276.513 -406.337 -72.7756 -128.983 14.471Z;
                                         M 33.6198 78.7942C134.498 185.161 81.9856 228.181 51.8253 350.305C29.6834 439.962 51.9958 594.158 -76.3181 648.724C-204.632 703.291 -508.634 694.222 -571.316 538.896C-633.997 383.569 -641.515 320.848 -481.082 256.106C-320.649 191.364 -171.736 -151.985 33.6198 78.7942Z;" keyTimes="0; 0.5; 1" dur="20s" repeatCount="indefinite" />
            </path>

            <linearGradient id="paint0_linear_59_5455" x1="-664.871" y1="324.373" x2="-8.28116" y2="62.1575" gradientUnits="userSpaceOnUse">
                <stop offset="0.0104167" stopColor="#FD880F" />
                <stop offset="0.489987" stopColor="#E93322" />
                <stop offset="1" stopColor="#9E1FCD" />
            </linearGradient>
        </svg>
    )
}

export default AnimationLefttSide
