import React from 'react'

function AnimationRightSide() {
    return (
        <svg width="300" height="727" viewBox="0 0 98 727" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill="url(#paint0_linear_59_5456)">
                <animate attributeName="d" values="M 395.718 14.7085C531.754 52.7999 509.594 114.35 545.04 230.274C571.062 315.379 665.768 431.882 586.623 540.466C507.477 649.051 251.55 791.882 122.896 694.406C-5.75819 596.931 -42.9925 548.772 57.687 415.888C158.366 283.005 111.745 -74.6204 395.718 14.7085Z;
                                         M 548.205 670.119C431.504 753.28 397.013 696.59 282.906 650.761C199.135 617.117 46.8364 617.713 11.4932 486.075C-23.85 354.437 26.3339 61.4008 185.226 21.8818C344.117 -17.6372 405.855 -16.3716 446.673 147.743C487.491 311.858 799.567 502.769 548.205 670.119Z;
                                         M 395.718 14.7085C531.754 52.7999 509.594 114.35 545.04 230.274C571.062 315.379 665.768 431.882 586.623 540.466C507.477 649.051 251.55 791.882 122.896 694.406C-5.75819 596.931 -42.9925 548.772 57.687 415.888C158.366 283.005 111.745 -74.6204 395.718 14.7085Z;
                                         " keyTimes="0; 0.5; 1" dur="40s" repeatCount="indefinite" />
            </path>

            <linearGradient id="paint0_linear_59_5456" x1="-60.5669" y1="563.238" x2="352.834" y2="21.6686" gradientUnits="userSpaceOnUse">
                <stop offset="0.0104167" stopColor="#FD880F" />
                <stop offset="0.489987" stopColor="#E93322" />
                <stop offset="1" stopColor="#9E1FCD" />
            </linearGradient>
        </svg>
    )
}

export default AnimationRightSide
