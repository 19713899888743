import React from 'react';
import Icon from './icon';

function IconCart(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon width="25" height="29" viewBox="0 0 25 29" {...props}>
      <path
        d="M19.5 7.5H24.75V24.125C24.75 25.3281 24.3125 26.3672 23.4375 27.2422C22.5625 28.1172 21.5781 28.5 20.375 28.5H4.625C3.42188 28.5 2.38281 28.1172 1.50781 27.2422C0.632812 26.3672 0.25 25.3281 0.25 24.125V7.5H5.5C5.5 6.24219 5.77344 5.09375 6.42969 4C7.03125 2.90625 7.85156 2.08594 8.94531 1.42969C10.0391 0.828125 11.1875 0.5 12.5 0.5C13.7578 0.5 14.9062 0.828125 16 1.42969C17.0938 2.08594 17.9141 2.90625 18.5703 4C19.1719 5.09375 19.5 6.24219 19.5 7.5ZM12.5 3.125C11.2969 3.125 10.2578 3.5625 9.38281 4.4375C8.50781 5.3125 8.125 6.29688 8.125 7.5H16.875C16.875 6.29688 16.4375 5.3125 15.5625 4.4375C14.6875 3.5625 13.7031 3.125 12.5 3.125ZM22.125 24.125V10.125H19.5V12.3125C19.5 12.6953 19.3359 13.0234 19.1172 13.2422C18.8438 13.5156 18.5156 13.625 18.1875 13.625C17.8047 13.625 17.4766 13.5156 17.2578 13.2422C16.9844 13.0234 16.875 12.6953 16.875 12.3125V10.125H8.125V12.3125C8.125 12.6953 7.96094 13.0234 7.74219 13.2422C7.46875 13.5156 7.14062 13.625 6.8125 13.625C6.42969 13.625 6.10156 13.5156 5.88281 13.2422C5.60938 13.0234 5.5 12.6953 5.5 12.3125V10.125H2.875V24.125C2.875 24.6172 3.03906 25.0547 3.36719 25.3828C3.69531 25.7109 4.13281 25.875 4.625 25.875H20.375C20.8125 25.875 21.25 25.7109 21.5781 25.3828C21.9062 25.0547 22.125 24.6172 22.125 24.125Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default IconCart;
