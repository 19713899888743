import axios from 'axios';
import { CartAddType } from '../component/app/cart/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
// API
export const addToCart = (body: CartAddType) => {
  return axios.post('add-to-cart', body);
};

export const getCart = (cartId: number) => {
  return axios.get(`get-cart/${cartId}`);
};

export const modifyCart = (cartId: number, body: CartAddType) => {
  return axios.post(`modify-cart-items/${cartId}`, body);
};

export const checkOut = (cartId: number) => {
  return axios.post(`checkout/${cartId}`);
};

// Query
export const useGetCartQuery = (cartId: number) => {
  return useQuery('getCart', () => getCart(cartId), { retry: false });
};

export const useAddToCartQuery = () => {
  const queryClient = useQueryClient();

  return useMutation('addToCart', (body: CartAddType) => addToCart(body), {
    retry: false, onSuccess: () => {
      return queryClient.invalidateQueries("getUserData")
    }
  });
};

export const useModifyCartQuery = (cartId: number) => {
  const queryClient = useQueryClient();

  return useMutation('modifyCart', (body: CartAddType) => modifyCart(cartId, body), {
    retry: false, onSuccess: () => {
      return queryClient.invalidateQueries("getUserData")
    }
  });
};


export const useCheckOutQuery = () => {
  return useMutation('checkOut', (cartId: number) => checkOut(cartId), { retry: false });
};
