import React from 'react';
import Icon from './icon';

function IconArrowDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon
      viewBox="0 0 24 24"
      {...props}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19 9l-7 7-7-7"
      />
    </Icon>
  );
}

export default IconArrowDown;
