// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Profile_card__H6iWB {\n  box-shadow: 0 0 20px 0 #acacac;\n  transition: transform ease 0.75s;\n  padding: 2rem;\n}\n.Profile_card__H6iWB:hover {\n  transform: scale(1.07);\n}", "",{"version":3,"sources":["webpack://./src/Containers/Profile/Profile.module.scss"],"names":[],"mappings":"AAEA;EACE,8BAAA;EACA,gCAAA;EACA,aAAA;AADF;AAGE;EACE,sBAAA;AADJ","sourcesContent":["@import \"src/scss/abstracts/variables\";\n\n.card {\n  box-shadow: 0 0 20px 0 #acacac;\n  transition: transform ease .75s;\n  padding: 2rem;\n\n  &:hover {\n    transform: scale(1.07);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "Profile_card__H6iWB"
};
export default ___CSS_LOADER_EXPORT___;
