import React from "react";
import Header from "../app/header/header";
import ProtectedRoute from "../global/auth/protectedRoute";
import SearchProduct from "../../Containers/Product/SearchProduct/SearchProduct";

function SearchProductPage() {
  return (
    <ProtectedRoute
      component={
        <>
          <Header />
          <SearchProduct />
        </>
      }
    />
  );
}

export default SearchProductPage;
