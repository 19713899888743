import React from "react";
import logo from "../../../favicon.png";
import { NavLink } from "react-router-dom";
import HeaderNavigation from "./headerNavigation";
import { useAuth } from "../../../store/auth";

function Header() {
  const { isLoggedIn } = useAuth();

  return (
    <div className="flex justify-between px-5 md:px-10 py-2 min-h-10 md:h-20 items-center  bg-white">
      <NavLink to="/">
        <img src={logo} alt="" className="max-w-[125px] max-h-full" />
      </NavLink>
      {isLoggedIn && <HeaderNavigation />}
    </div>
  );
}

export default Header;
