import React from "react";
import CheckOut from "../../Containers/CheckOut/CheckOut";
import HeaderCheckout from "../app/header/headerCheckout";
import ProtectedRoute from "../global/auth/protectedRoute";

function CheckoutPage() {
  return (
    <ProtectedRoute
      component={
        <>
          <HeaderCheckout />
          <CheckOut />
        </>
      }
    />
  );
}

export default CheckoutPage;
