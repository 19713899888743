import { UserDataType } from "../../component/app/user/types"

export interface UserUpdateAction {
    type: "UPDATE_USER"
    payload: UserDataType
}

export interface UserUpdateMetaAction {
    type: "CLEAR_USER"
}

export type UserActions = UserUpdateAction | UserUpdateMetaAction

export function updateUser(payload: UserDataType): UserActions {
    return { type: 'UPDATE_USER', payload };
}

export function clearUser(): UserActions {
    return { type: 'CLEAR_USER' };
}
