// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProductFilter_filter__m5tmr {\n  display: none;\n}\n.ProductFilter_filter__container__R2k93 {\n  margin: 0.75rem 0.625rem 0 0;\n}\n.ProductFilter_filter__m5tmr + label {\n  display: block;\n  width: -moz-fit-content;\n  width: fit-content;\n  border: 0.125rem solid #20212a;\n  text-transform: uppercase;\n  font-weight: bold;\n  padding: 8px;\n  font-size: 0.875rem;\n}\n.ProductFilter_filter__m5tmr:checked + label {\n  border-color: #fd880f;\n  background-color: #fd880f;\n  color: #ffffff;\n}\n.ProductFilter_filter__m5tmr:hover + label {\n  background-color: #fd880f;\n  color: white;\n  border-color: #fd880f;\n}", "",{"version":3,"sources":["webpack://./src/Containers/Product/ProductFilter/ProductFilter.module.scss","webpack://./src/scss/abstracts/_variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;AADF;AAGE;EACE,4BAAA;AADJ;AAIE;EACE,cAAA;EACA,uBAAA;EAAA,kBAAA;EACA,8BAAA;EACA,yBAAA;EACA,iBAAA;EACA,YAAA;EACA,mBAAA;AAFJ;AAKE;EACE,qBCnBK;EDoBL,yBCpBK;EDqBL,cCbI;ADUR;AAME;EACE,yBCzBK;ED0BL,YAAA;EACA,qBC3BK;ADuBT","sourcesContent":["@import \"src/scss/abstracts/variables\";\n\n.filter {\n  display: none;\n\n  &__container {\n    margin: 0.75rem 0.625rem 0 0;\n  }\n\n  & + label {\n    display: block;\n    width: fit-content;\n    border: 0.125rem solid $black;\n    text-transform: uppercase;\n    font-weight: bold;\n    padding: 8px;\n    font-size: 0.875rem;\n  }\n\n  &:checked + label {\n    border-color: $orange;\n    background-color: $orange;\n    color: $white;\n  }\n\n  &:hover + label {\n    background-color: $orange;\n    color: white;\n    border-color: $orange;\n  }\n}\n","$blood-orange: #ea3523;\n$orange: #fd880f;\n$green: #56b95f;\n$purple: #9e1fcd;\n\n$black: #20212a;\n$gray-secondary: #828282;\n$gray-image-bg: #e5e5e5;\n$gray-bg: #f4f4f4;\n$white: #ffffff;\n\n$text-primary: rgba(32, 33, 42, 0.63);\n$text-secondary: #9e1fcd;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": "ProductFilter_filter__m5tmr",
	"filter__container": "ProductFilter_filter__container__R2k93"
};
export default ___CSS_LOADER_EXPORT___;
