import React, { useState } from 'react';

import Book from './Book';
import AddNewAddress from './AddNewAddress';
import EditAddressBook from './EditAddressBook';

const AddressBook = (props) => {
  const [addresses, setAddresses] = useState([]);
  const [editAddress, setEditAddress] = useState([]);
  const [view, setView] = useState('book');

  const display = {
    book: <Book setView={setView} addresses={addresses} setEditAddress={setEditAddress} setActiveView={props.setActiveView} />,
    new: <AddNewAddress setView={setView} setAddresses={setAddresses} />,
    edit: <EditAddressBook setView={setView} setAddresses={setAddresses} editAddress={editAddress} />,
  };

  return <div>{display[view]}</div>;
};

export default AddressBook;
