import React from "react";
import style from "./cart.module.scss";
import { useCart } from "../../../store/cart";
import Loading from "../../global/loading/loading";
import { useAppDispatch } from "../../../store/store";
import { formaDeliveryDate } from "../../../libs/date";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCheckOutQuery, useModifyCartQuery } from "../../../services/cart.service";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  removeCartItem,
  updateCartItemQuantity,
} from "../../../store/actions/cart";
import {
  CartProductPricesTiersType,
  CartStateType,
  mapCartAdd,
  UICartPackageType,
} from "./types";
import Button from "../../global/button/Button";
import { useNavigate } from "react-router-dom";
import IconTrash from "../../global/icons/iconTrash";

function Cart() {
  const cartState = useCart();
  const navigate = useNavigate();
  const cartCheckoutMutation = useCheckOutQuery();
  const updateMutation = useModifyCartQuery(cartState.cart.id);

  const handleProceedToCheckout = async () => {
    if (updateMutation.isLoading || cartCheckoutMutation.isLoading) {
      return;
    }

    await updateMutation.mutateAsync(mapCartAdd(cartState.cart));

    await cartCheckoutMutation.mutateAsync(cartState.cart.id);

    navigate("/checkout");
  };

  const hasItems = cartState.uiCartItemsCount > 0;

  const loading = updateMutation.isLoading || cartCheckoutMutation.isLoading

  return (
    <div className="px-5 md:px-10">
      {loading ? (
        <Loading />
      ) : (
        <div className="sm:flex flex-col justify-around py-5 md:grid md:grid-cols-[3fr_2fr] gap-10">
          <div className="flex flex-col mt-2 md:mt-20">
            <div className="text-4xl uppercase font-bold mb-5">
              {" "}
              Shopping cart
            </div>
            {hasItems ? (
              <CartItems cartState={cartState} />
            ) : (
              "Your cart is empty!"
            )}
          </div>
          {hasItems && (
            <div className="flex flex-col mt-2 md:mt-20">
              <div className="text-4xl uppercase font-bold pb-10 border-gray-300 border-b">
                summary
              </div>
              <div className="border-gray-300 border-b pb-5 flex flex-col">
                <div className="flex justify-between mt-5">
                  <div className="text-md">Subtotal:</div>
                  <strong>
                    ${cartState.cart.total.toLocaleString("en-US")}
                  </strong>
                </div>
                <div className="flex justify-between ">
                  <div className="text-md">Estimated Delivery:</div>
                  <div>
                    {formaDeliveryDate(cartState.cart.estimated_delivery)}
                  </div>
                </div>
              </div>
              <div className="flex justify-between mt-5 text-xl text-[#EA3523]">
                <strong>TOTAL</strong>
                <strong>${cartState.cart.total.toLocaleString("en-US")}</strong>
              </div>
              <div className="flex justify-center mt-5">
                <Button
                  class="rainbow"
                  text="proceed to checkout"
                  click={handleProceedToCheckout}
                />
              </div>
              <div className="flex justify-center mt-10">
                * We will ship the entire order to you in one shipment, so
                whichever ship date is the farthest out will determine the ETA.
                If you require some products sooner, please create a separate
                order. Give us a chat if you have any questions.
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Cart;

interface CartItemsProps {
  cartState: CartStateType;
}

const MIN_QUANTITY = 0;
const MAX_QUANTITY = 999;
const UPDATE_DELAY = 500; //ms

function CartItems(props: CartItemsProps) {
  const { cartState } = props;
  const dispatch = useAppDispatch();
  const updateTimerRef = React.useRef<NodeJS.Timeout>();
  const updateMutation = useModifyCartQuery(cartState.cart.id);

  React.useEffect(() => {
    return () => {
      clearTimeout(updateTimerRef.current);
    };
  }, []);

  const updateCart = () => {
    return updateMutation.mutateAsync(mapCartAdd(cartState.cart));
  };

  const getTopQuantity = (tiers: CartProductPricesTiersType[]) => {
    if (tiers.constructor.name !== "Array") return MAX_QUANTITY;
    const values = tiers?.map((tier) => tier.max);
    return Math.max(...values);
  };

  const handleQuantityChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    cartPackageType: UICartPackageType
  ) => {
    const quantity = e.target.valueAsNumber;

    if (quantity > MAX_QUANTITY || updateMutation.isLoading) {
      return;
    }

    if (quantity <= MIN_QUANTITY) {
      dispatch(removeCartItem(cartPackageType));
    } else {
      dispatch(
        updateCartItemQuantity({
          cartPackageType,
          quantity,
        })
      );
    }
    clearTimeout(updateTimerRef.current);
    updateTimerRef.current = setTimeout(() => {
      updateCart();
    }, UPDATE_DELAY);
  };

  const handleRemovePackageItem = (
    e: React.MouseEvent | React.ChangeEvent<HTMLInputElement>,
    cartPackageType: UICartPackageType
  ) => {
    e.preventDefault();
    if (updateMutation.isLoading) {
      return;
    }
    dispatch(removeCartItem(cartPackageType));
    clearTimeout(updateTimerRef.current);
    updateTimerRef.current = setTimeout(() => {
      updateCart();
    }, UPDATE_DELAY);
  };

  const fileterNoQuantity = (
    packages: UICartPackageType[]
  ): UICartPackageType[] => {
    return packages.filter((pcg) => pcg.quantity !== 0);
  };

  return (
    <>
      {cartState.uiCartItems.map((cartItem) => (
        <div
          className="border-b border-gray-300 grid lg:grid-cols-[1fr_4fr] gap-5"
          key={`cart-product-${cartItem.product_id}`}
        >
          <div className="flex">
            <img
              src={cartItem.cover_image}
              alt="cover"
              className="!max-w-[80%] max-h-[250px] mx-auto my-auto py-6"
            />
          </div>

          <div className="flex flex-col">
            <div className="font-bold uppercase text-2xl my-3">
              {cartItem.product_name}
            </div>

            {fileterNoQuantity(cartItem.package_types).map(
              (cartPackageType) => (
                <div key={`package-type-item-${cartPackageType.type}`}>
                  <div className="hidden xl:grid xl:grid-cols-7 xl:grid-flow-row justify-between items-center w-full gap-5 mb-5">
                    <div className="font-bold uppercase col-span-3">
                      {cartPackageType.title}
                    </div>
                    {cartPackageType.type === "pdf" ? (
                      <div className={"toggle-switch small-switch"}>
                        <input
                          type="checkbox"
                          name={cartPackageType.type}
                          className="toggle-switch-checkbox"
                          checked={cartPackageType.quantity > 0}
                          id={cartPackageType.id + "pdf_desktop"}
                          onChange={(e) =>
                            handleRemovePackageItem(e, cartPackageType)
                          }
                        />
                        <label
                          className="toggle-switch-label"
                          htmlFor={cartPackageType.id + "pdf_desktop"}
                        >
                          <span className={"toggle-switch-inner"} />
                          <span className={"toggle-switch-switch"} />
                        </label>
                      </div>
                    ) : (
                      <input
                        min="0"
                        type="number"
                        name={cartPackageType.type}
                        value={cartPackageType.quantity}
                        max={getTopQuantity(cartPackageType.tiers)}
                        className={"max-w-[82px] " + style.input}
                        onChange={(e) =>
                          handleQuantityChange(e, cartPackageType)
                        }
                      />
                    )}
                    <div className="text-md">
                      ${cartPackageType.price.toLocaleString("en-US")} / ea
                    </div>
                    <strong className="text-md">
                      $
                      {(
                        cartPackageType.price * cartPackageType.quantity
                      ).toLocaleString("en-US")}
                    </strong>
                    <div
                      className="ml-auto cursor-pointer"
                      onClick={(e) =>
                        handleRemovePackageItem(e, cartPackageType)
                      }
                    >
                      <IconTrash style={{ color: "#20212A" }} />
                    </div>
                  </div>
                  <div className="flex flex-wrap xl:hidden w-full  mb-5">
                    <div className="flex w-full justify-between">
                      <div className="font-bold uppercase">
                        {cartPackageType.type}
                      </div>
                      <FontAwesomeIcon
                        onClick={(e) =>
                          handleRemovePackageItem(e, cartPackageType)
                        }
                        className="cursor-pointer"
                        icon={solid("trash-can")}
                        color="black"
                      />
                    </div>
                    <div className="flex w-full justify-between">
                      {cartPackageType.type === "pdf" ? (
                        <div className="block">
                          <div className={"toggle-switch small-switch"}>
                            <input
                              type="checkbox"
                              name={cartPackageType.type}
                              className="toggle-switch-checkbox"
                              id={cartPackageType.id + "mobile"}
                              checked={cartPackageType.quantity > 0}
                              onChange={(e) =>
                                handleRemovePackageItem(e, cartPackageType)
                              }
                            />
                            <label
                              className="toggle-switch-label"
                              htmlFor={cartPackageType.id + "mobile"}
                            >
                              <span className={"toggle-switch-inner"} />
                              <span className={"toggle-switch-switch"} />
                            </label>
                          </div>
                        </div>
                      ) : (
                        <input
                          min="0"
                          type="number"
                          max={MAX_QUANTITY}
                          className="max-w-[82px]"
                          name={cartPackageType.type}
                          defaultValue={cartPackageType.quantity}
                          onChange={(e) =>
                            handleQuantityChange(e, cartPackageType)
                          }
                        />
                      )}
                      <div className="flex flex-wrap justify-end">
                        <div className="w-full text-right">
                          ${cartPackageType.price.toLocaleString("en-US")} / ea
                        </div>
                        <strong>
                          <div className="text-md">
                            $
                            {(
                              cartPackageType.price * cartPackageType.quantity
                            ).toLocaleString("en-US")}
                          </div>
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      ))}
    </>
  );
}
