import React, { forwardRef, useEffect, useState } from "react";
import UserService from "../../../services/user.service";
import classes from "./DateSelect.module.scss";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Button from "../../../component/global/button/Button";
import { useNavigate } from "react-router-dom";
import IconCalendar from "../../../component/global/icons/iconCalendar";
import {
  formaDeliveryDate,
  formatEstimatedDeliveryDate,
} from "../../../libs/date";

const DateSelect = (props) => {
  useEffect(() => {
    props.setPhase(2);
  }, []);
  const navigate = useNavigate();
  const [order] = useState(props.order);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState(
    props.order.estimated_delivery
  );

  const getDeliveryDate = () => {
    return formaDeliveryDate(order.estimated_delivery);
  };
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div
      ref={ref}
      onClick={onClick}
      className="flex gap-x-10 py-2  px-2 w-fit border border-black mt-5 cursor-pointer"
    >
      <div className=" ">{value}</div>
      <div className="flex">
        <IconCalendar
          width="13"
          height="15"
          style={{
            color: "#20212A",
          }}
        />
      </div>
    </div>
  ));
  const getDatePicker = () => {
    const isWeekday = (date) => {
      const day = date.getDay();
      return day !== 0 && day !== 6;
    };
    const minDate = new Date(order.estimated_delivery);
    const delivery = new Date(deliveryDate);

    return (
      <DatePicker
        selected={delivery}
        onChange={(date) => {
          setDeliveryDate(formatEstimatedDeliveryDate(new Date(date)));
        }}
        filterDate={isWeekday}
        dateFormat="MM/dd/yyyy"
        calendarStartDay={1}
        customInput={<ExampleCustomInput />}
        minDate={minDate}
      />
    );
  };
  return (
    <div>
      <div className="mx-auto w-full md:w-2/3 mt-8 px-5 md:px-0 pb-5 md:pb-0">
        <h3 className="uppercase text-center text-2xl font-bold mb-6 md:mb-20">
          Current Delivery Date
        </h3>
        <div className="flex flex-col text-left">
          <div className="flex w-full justify-between py-5 border-y">
            <div className="flex items-center justify-center">
              <label className={classes["container"]}>
                <strong>Current Delivery Date:</strong> {getDeliveryDate()}
                <input
                  type="radio"
                  id="default"
                  name="date"
                  className={classes["custom-radio"]}
                  defaultChecked={true}
                  onChange={() => {
                    setShowDatePicker(false);
                    setDeliveryDate(order.estimated_delivery);
                  }}
                />
                <span className={classes["checkmark"]} />
              </label>
            </div>
            <div className="font-bold uppercase text-xs">free</div>
          </div>
          <div className="py-10 border-y">
            <div className="flex w-full justify-between">
              <div className="flex items-center justify-center">
                <label className={classes["container"]}>
                  <strong>Schedule Future Ship Date</strong>
                  <input
                    type="radio"
                    id="custom"
                    name="date"
                    className=" rounded-full"
                    required={true}
                    onChange={() => setShowDatePicker(true)}
                  />
                  <span className={classes["checkmark"]} />
                </label>
              </div>
              <div className="font-bold uppercase text-xs">free</div>
            </div>
            {showDatePicker &&
              /*<input type="date" id='date' min={order.estimated_delivery}
                               className={classes['date'] + ' mt-6'}
                               onChange={(e) => {
                                   setDeliveryDate(e.target.value)
                               }}/>*/
              getDatePicker()}
          </div>
        </div>
        <div className="flex flex-col items-center md:flex-row justify-between gap-5 mt-10">
          <Button
            class="ghost"
            text="back to cart"
            click={() => {
              navigate("/cart");
            }}
          />
          <Button
            text="continue"
            click={() => {
              UserService.setOrderDeliveryDate(
                props.orderID,
                deliveryDate
              ).then((response) => {
                props.setOrder(response.data.data);
                props.setActiveView("complete");
              });
            }}
            class="rainbow"
          />
        </div>
      </div>
    </div>
  );
};

export default DateSelect;
