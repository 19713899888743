import React from "react";
import Header from "../app/header/header";
import Login from "../../Containers/Login/Login";
import GuestRoute from "../global/auth/guestRoute";

function LoginPage() {
  return (
    <GuestRoute
      component={
        <>
          <Header />
          <Login />
        </>
      }
    />
  );
}

export default LoginPage;
