import React from "react";
import Header from "../app/header/header";
import GuestRoute from "../global/auth/guestRoute";
import ResetPassword from "../../Containers/ResetPassword/ResetPassword";

function PasswordResetPage() {
  return (
    <GuestRoute
      component={
        <>
          <Header />
          <ResetPassword />
        </>
      }
    />
  );
}

export default PasswordResetPage;
