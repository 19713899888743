import React, { useEffect, useState } from "react";
import Button from "../../../component/global/button/Button";
import IconArrowLeftVariant from "../../../component/global/icons/iconArrowLeftVariant";
import Loading from "../../../component/global/loading/loading";
import UserService from "../../../services/user.service";

const Book = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [addresses, setAddresses] = useState([]);
  useEffect(() => {
    UserService.getAddresses().then((res) => {
      setAddresses(res.data.data);
      setIsLoading(false);
    });
  }, []);
  const getAddresses = () => {
    return addresses.map((address) => (
      <div
        key={address.id}
        className="flex flex-col md:flex-row w-full justify-between pb-5 border-b"
      >
        <div className="flex flex-col text-left pb-10 pt-4 md:py-6 ">
          <div className="flex w-full justify-between">
            <strong className="mb-4 md:mb-8">
              {" "}
              {address.first_name} {address.last_name}
            </strong>
          </div>
          <div>{address.company_name}</div>
          <div>
            {address.address} {address.address2}
          </div>
          <div>
            {address.city}, {address.state} {address.zipcode}
          </div>
        </div>
        <div className="flex justify-between py-0 md:py-6">
          <div className="mr-6">
            <Button
              text="modify"
              class="ghost-sm"
              click={() => {
                UserService.getAddress(address.id).then((res) => {
                  props.setEditAddress(res.data.data);
                  props.setView("edit");
                });
              }}
            />
          </div>

          <Button
            text="delete"
            class="ghost-sm"
            click={() => {
              UserService.deleteAddress(address.id).then((res) => {
                setIsLoading(true);
                UserService.getAddresses().then((res) => {
                  setAddresses(res.data.data);
                  setIsLoading(false);
                });
              });
            }}
          />
        </div>
      </div>
    ));
  };
  return (
    <div>
      <div className="mt-5 md:mt-20 md:mb-8">
        <h3 className="font-bold text-xl uppercase pb-10 border-b hidden md:block">
          address book
        </h3>
        <h3
          className="font-bold text-xl uppercase pb-6 pt-2 border-b text-[#9E1FCD] flex md:hidden items-center gap-3 cursor-pointer"
          onClick={() => {
            props.setActiveView("landing");
          }}
        >
          <IconArrowLeftVariant style={{ color: "#9E1FCD" }} />
          address book
        </h3>
        {isLoading ? (
          <Loading />
        ) : (
          <div className="flex flex-col pb-20">
            <div className="mb-20">{getAddresses()}</div>
            <div className="flex justify-center md:justify-start">
              <Button
                text="add new address"
                class="rainbow"
                click={() => {
                  props.setView("new");
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Book;
