import React from "react";

const PasswordFieldLength = (value: string) => {
  if (value.length < 6) {
    return (
      <div className="text-white absolute" role="alert">
        The password must be at least 6 characters.
      </div>
    );
  }
};

export default PasswordFieldLength;
