import React, { useRef, useState } from "react";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import UserService from "../../../services/user.service";
import CheckButton from "react-validation/build/button";
import StatesAutocomplete from "../../../component/global/autocomplete/statesAutocomplete";
import Button from "../../../component/global/button/Button";
import RequiredField from "../../../component/global/fileds/requiredField";

const EditAddressBook = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const [company, setCompany] = useState(props.editAddress.company_name);
  const [fName, setFName] = useState(props.editAddress.first_name);
  const [lName, setLName] = useState(props.editAddress.last_name);
  const [address, setAddress] = useState(props.editAddress.address);
  const [address2, setAddress2] = useState(props.editAddress.address2);
  const [city, setCity] = useState(props.editAddress.city);
  const [state, setState] = useState(props.editAddress.state);
  const [zip, setZip] = useState(props.editAddress.zipcode);
  const [phone, setPhone] = useState(props.editAddress.phone);
  // const [special, setSpecial] = useState(props.editAddress.special_instructions);
  const [, setSuccessful] = useState(false);

  const onChangeCompany = (e) => {
    setCompany(e.target.value);
  };
  const onChangeFName = (e) => {
    setFName(e.target.value);
  };
  const onChangeLName = (e) => {
    setLName(e.target.value);
  };
  const onChangeAddress = (e) => {
    setAddress(e.target.value);
  };
  const onChangeAddress2 = (e) => {
    setAddress2(e.target.value);
  };
  const onChangeCity = (e) => {
    setCity(e.target.value);
  };
  const onChangeZip = (e) => {
    setZip(e.target.value);
  };
  const onChangeState = (value) => {
    setState(value);
  };
  const onChangePhone = (e) => {
    setPhone(e.target.value);
  };
  const handleAddress = (e) => {
    e.preventDefault();
    setSuccessful(false);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      UserService.updateAddress(
        props.editAddress.id,
        company,
        fName,
        lName,
        address,
        address2,
        city,
        state,
        zip,
        phone,
        ""
      )
        .then(
          (response) => {
            UserService.getAddresses().then(
              (response) => {
                props.setAddresses(response.data.data);
                props.setView("book");
              },
              (error) => {
                // const message =
                //   (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
              }
            );
          },
          (error) => {
            // const message =
            //   (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          }
        )
        .then(() => {
          setSuccessful(true);
        })
        .catch(() => {
          setSuccessful(false);
        });
    }
  };

  return (
    <div>
      <Form
        onSubmit={handleAddress}
        ref={form}
        className="w-full flex md:items-center justify-center"
      >
        <div className="mx-auto w-full md:w-2/3 mb-5 md:mb-0 mt-8">
          <h3 className="uppercase text-center text-2xl font-bold mb-6">
            Delivery address
          </h3>
          <div className=" flex flex-col lg:grid  lg:grid-cols-[1fr_1fr] md:gap-x-5">
            <div className="items-center justify-center w-full mb-6">
              <fieldset className="border border-gray-500">
                <legend className="text-left ml-4 px-3  uppercase">
                  Company Name <i className="lowercase">(optional)</i>
                </legend>
                <Input
                  type="text"
                  className="form-control custom-input m-1 "
                  name="company_name"
                  value={company}
                  onChange={onChangeCompany}
                />
              </fieldset>
            </div>
            <div className="hidden md:block" />
            <div className="items-center justify-center w-full mb-6">
              <fieldset className="border border-gray-500">
                <legend className="text-left ml-4 px-3 uppercase">
                  First Name
                </legend>
                <Input
                  type="text"
                  className="form-control custom-input m-1 "
                  name="first_name"
                  value={fName}
                  onChange={onChangeFName}
                  validations={[RequiredField]}
                />
              </fieldset>
            </div>
            <div className="items-center justify-center w-full mb-6">
              <fieldset className="border border-gray-500">
                <legend className="text-left ml-4 px-3  uppercase">
                  Last Name
                </legend>
                <Input
                  type="text"
                  className="form-control custom-input m-1 "
                  name="last_name"
                  value={lName}
                  onChange={onChangeLName}
                  validations={[RequiredField]}
                />
              </fieldset>
            </div>
            <div className="items-center justify-center w-full mb-6">
              <fieldset className="border border-gray-500">
                <legend className="text-left ml-4 px-3  uppercase">
                  Address
                </legend>
                <Input
                  type="text"
                  className="form-control custom-input m-1 "
                  name="address"
                  value={address}
                  onChange={onChangeAddress}
                  validations={[RequiredField]}
                />
              </fieldset>
            </div>
            <div className="items-center justify-center w-full mb-6">
              <fieldset className="border border-gray-500">
                <legend className="text-left ml-4 px-3  uppercase">
                  Address 2 <i className="lowercase">(optional)</i>
                </legend>
                <Input
                  type="text"
                  className="form-control custom-input m-1 "
                  name="address_2"
                  value={address2}
                  onChange={onChangeAddress2}
                />
              </fieldset>
            </div>
            <div className="items-center justify-center w-full mb-6">
              <fieldset className="border border-gray-500">
                <legend className="text-left ml-4 px-3  uppercase">city</legend>
                <Input
                  type="text"
                  className="form-control custom-input m-1 "
                  name="city"
                  value={city}
                  onChange={onChangeCity}
                  validations={[RequiredField]}
                />
              </fieldset>
            </div>
            <div className="items-center justify-center w-full mb-6">
              <fieldset className="border border-gray-500">
                <legend className="text-left ml-4 px-3  uppercase">
                  State
                </legend>
                <StatesAutocomplete
                  initialValue={state}
                  onChange={onChangeState}
                />
                <Input
                  type="text"
                  hidden
                  name="state"
                  value={state}
                  validations={[RequiredField]}
                />
              </fieldset>
            </div>
            <div className="items-center justify-center w-full mb-6">
              <fieldset className="border border-gray-500">
                <legend className="text-left ml-4 px-3  uppercase">
                  zip code
                </legend>
                <Input
                  type="text"
                  className="form-control custom-input m-1 "
                  name="zip"
                  value={zip}
                  onChange={onChangeZip}
                  validations={[RequiredField]}
                />
              </fieldset>
            </div>
            <div className="items-center justify-center w-full mb-6">
              <fieldset className="border border-gray-500">
                <legend className="text-left ml-4 px-3  uppercase">
                  phone <i className="lowercase">(optional)</i>
                </legend>
                <Input
                  type="text"
                  className="form-control custom-input m-1 "
                  name="phone"
                  value={phone}
                  onChange={onChangePhone}
                />
              </fieldset>
            </div>
          </div>
          <div
            className="flex w-full flex-col xl:flex-row items-center justify-between
                            "
          >
            <Button text="continue" class="rainbow" />
            <CheckButton style={{ display: " none" }} ref={checkBtn} />
          </div>
        </div>
      </Form>
    </div>
  );
};
export default EditAddressBook;
