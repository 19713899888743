import React from "react";
import Header from "../app/header/header";
import GuestRoute from "../global/auth/guestRoute";
import Register from "../../Containers/Register/Register";

function RegisterPage() {
  return (
    <GuestRoute
      component={
        <>
          <Header />
          <Register />
        </>
      }
    />
  );
}

export default RegisterPage;
