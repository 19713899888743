import React from "react";
import classes from "../Profile.module.scss";
import { logout } from "../../../store/actions/auth";
import { useAppDispatch } from "../../../store/store";
import Button from "../../../component/global/button/Button";
import MobileAnimation from "../../../component/global/animation/mobileAnimation";
import DesktopAnimation from "../../../component/global/animation/desktopAnimation";
import IconOrders from "../../../component/global/icons/iconOrders";
import IconAddressBook from "../../../component/global/icons/iconAddressBook";
import IconAccountGradient from "../../../component/global/icons/iconAccountGradient";

const ProfileLanding = (props) => {
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="container overflow-hidden w-full mx-auto px-5 md:px-10 text-center login-page flex md:items-center md:justify-center">
      <div className="mobile ">
        <MobileAnimation />
      </div>
      <div className=" wrapper">
        <DesktopAnimation />
      </div>
      <div className="w-full flex flex-col items-center my-12 md:mt-0 ">
        <h3 className="text-white text-2xl font-bold uppercase ">
          Hello, {props.userData.first_name} {props.userData.last_name}{" "}
        </h3>
        <div className="grid lg:grid-cols-3 w-full my-5 md:my-12">
          <div
            className={
              classes["card"] +
              " bg-white  m-[15px] cursor-pointer !p-2 md:!p-8"
            }
            onClick={() => {
              props.setActiveView("orders");
            }}
          >
            <div className="flex items-center md:grid md:grid-cols-[1fr_3fr]">
              <div className="flex justify-center items-center p-5">
                <IconOrders />
              </div>
              <div className="flex flex-col text-left">
                <h3 className="text-purple-700 text-xl font-bold uppercase text-center md:text-left">
                  Orders
                </h3>
                <div className="text-base hidden md:block">
                  Take a look at all of your pending and past shipments.
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              classes["card"] +
              " bg-white  m-[15px] cursor-pointer !p-2 md:!p-8"
            }
            onClick={() => {
              props.setActiveView("address");
            }}
          >
            <div className="flex items-center md:grid md:grid-cols-[1fr_3fr]">
              <div className="flex justify-center items-center p-5">
                <IconAddressBook />
              </div>
              <div className="flex flex-col text-left">
                <h3 className="text-purple-700 text-xl font-bold uppercase text-center md:text-left">
                  address book
                </h3>
                <div className="text-base hidden md:block">
                  Include your address and any retail addresses you'd like to
                  ship to.
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              classes["card"] +
              " bg-white  m-[15px] cursor-pointer !p-2 md:!p-8"
            }
            onClick={() => {
              props.setActiveView("edit");
            }}
          >
            <div className="flex items-center md:grid md:grid-cols-[1fr_3fr]">
              <div className="flex justify-center items-center p-5">
                <IconAccountGradient />
              </div>
              <div className="flex flex-col text-left">
                <h3 className="text-purple-700 text-xl font-bold uppercase text-center md:text-left">
                  edit profile
                </h3>
                <div className="text-base hidden md:block">
                  Update your contact information.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full px-[15px]">
          <Button text="logout" click={handleLogout} />
        </div>
      </div>
    </div>
  );
};

export default ProfileLanding;
