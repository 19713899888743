import React from 'react';
import './toggleSwitch.scss';

interface Props {
  id: string;
  name?: string;
  small?: boolean;
  checked: boolean;
  disabled?: boolean;
  optionLabels?: string[];
  onChange(checked: boolean): void;
}

const ToggleSwitch = (props: Props) => {
  const { id, name, checked, small, disabled, onChange, optionLabels = ['No', 'Yes'] } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  return (
    <div className={'toggle-switch' + (small ? ' small-switch' : '')}>
      <input
        id={id}
        name={name}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
        data-no={optionLabels[0]}
        data-yes={optionLabels[1]}
        className="toggle-switch-checkbox"
      />
      {id ? (
        <label className="toggle-switch-label" htmlFor={id}>
          <span className={disabled ? 'toggle-switch-inner toggle-switch-disabled' : 'toggle-switch-inner'} />
          <span className={disabled ? 'toggle-switch-switch toggle-switch-disabled' : 'toggle-switch-switch'} />
        </label>
      ) : null}
    </div>
  );
};

export default ToggleSwitch;
