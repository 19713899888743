import React from "react";
const validator = require("validator");

const RequiredEmailWhite = (value?: string) => {
  if (!validator.isEmail(value?.trim())) {
    return (
      <div className="text-white absolute" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

export default RequiredEmailWhite;
