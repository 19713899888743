import React from 'react';
import classes from './loading.module.scss';

function Loading() {
  return (
    <div className={classes['loading']}>
      <main>
        <div>
          <div>
            <span />
          </div>
          <div>
            <span />
          </div>
        </div>
        <span>
          <span />
        </span>
        <span>
          <span />
        </span>
        <span>
          <span />
        </span>
        <span>
          <span />
        </span>
        <span>
          <span />
        </span>
      </main>
    </div>
  );
}

export default Loading;
