import React, { useEffect, useState } from "react";
import UserService from "../../../services/user.service";
import { useAppDispatch } from "../../../store/store";
import { clearCart } from "../../../store/actions/cart";
import { logger } from "../../../libs/logger/logger";
import Button from "../../../component/global/button/Button";
import { useNavigate } from "react-router-dom";
import IconArrowDown from "../../../component/global/icons/iconArrowDown";
import IconArrowUp from "../../../component/global/icons/iconArrowUp";

const CompleteOrder = (props) => {
  useEffect(() => {
    props.setPhase(3);
  }, []);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [order] = useState(props.order);
  const [isActive, setIsActive] = useState(false);
  const [orderItems, setOrderItems] = useState([]);

  const getDeliveryDate = () => {
    const date = new Date(order.estimated_delivery);
    const formattedDate = date.toLocaleDateString("en-US", {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return formattedDate;
  };
  const getDeliveryDateM = () => {
    const date = new Date(order.estimated_delivery);
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return formattedDate;
  };
  useEffect(() => {
    getOrderItems();
  }, [order]);

  const getOrderItems = () => {
    let local = [];
    order.order_items.forEach((y) => {
      if (
        local.filter((copy) => copy.product_id === y.product.id).length === 0 &&
        y.quantity !== 0
      ) {
        local.push({
          product_id: y.product.id,
          product_name: y.product.name,
          cover_image: y.product.cover_image,
          package_types: [
            {
              id: y.id,
              type: y.package_type,
              title: y.package_title,
              quantity: y.quantity,
              eta: y.eta,
              price: y.price,
            },
          ],
        });
      } else {
        if (y.quantity !== 0) {
          local
            .find((copy) => copy.product_id === y.product.id)
            .package_types.push({
              id: y.id,
              type: y.package_type,
              title: y.package_title,
              quantity: y.quantity,
              eta: y.eta,
              price: y.price,
            });
        }
      }
      setOrderItems(local);
    });
  };

  const handleComplete = () => {
    UserService.completeOrder(props.orderID).then((response) => {
      dispatch(clearCart);
      logger.log("completeOrder =>");
      props.setActiveView("thanks");
    });
  };

  const getItems = () => {
    return orderItems.map((item) => (
      <div
        key={`order-item-${item.product_id}`}
        className="flex flex-col gap-5 w-full  pb-5 border-b-2 "
      >
        <div className="uppercase font-bold text-lg mt-5">
          {item.product_name}
        </div>
        {item.package_types.map((pack) => (
          <div
            key={`order-item-pack-${pack.id}`}
            className="grid grid-cols-[2fr_1fr_1fr]"
          >
            <div className="font-bold uppercase">{pack.title}</div>
            <div className="font-bold">{pack.quantity}</div>
            <div className="font-bold text-right">
              ${(pack.price * pack.quantity).toLocaleString("en-US")}
            </div>
          </div>
        ))}
      </div>
    ));
  };

  return (
    <div>
      <div className="mx-auto w-full md:w-2/3 mt-8 px-5 md:px-0 pb-5 md:pb-8">
        <h3 className="uppercase text-center text-2xl font-bold mb-8">
          Order summary
        </h3>
        <div className="md:grid md:grid-cols-2 text-left border-b-2 pb-5 md:pb-20">
          <div className="flex flex-col pb-10 md:pb-0">
            <h3 className="uppercase font-bold text-lg mb-4">
              delivery address
            </h3>
            <div className="flex flex-col text-left">
              <strong className="mb-4">
                {" "}
                {order.delivery_address.first_name}{" "}
                {order.delivery_address.last_name}
              </strong>
              <div>{order.delivery_address.company_name}</div>
              <div>
                {order.delivery_address.address}{" "}
                {order.delivery_address.address2}
              </div>
              <div>
                {order.delivery_address.city}, {order.delivery_address.state}{" "}
                {order.delivery_address.zipcode}
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <h3 className="uppercase font-bold text-lg mb-4">
              shipping method
            </h3>
            <div className="flex flex-col text-left">
              <strong className="uppercase">estimated delivery</strong>
              <div className="mb-4">{getDeliveryDate()}</div>
              <strong className="uppercase">delivery mode</strong>
              <div>FEDEX</div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <div
            className={
              isActive
                ? "border-y border-t-black flex justify-between py-5"
                : "border-b border-gray-300 flex justify-between py-5"
            }
          >
            <button className="accordion-title w-full font-bold">
              <h2 onClick={() => setIsActive(!isActive)}>
                <div className="flex w-full justify-between">
                  <div className="uppercase text-base font-bold">
                    order details
                  </div>
                  {isActive ? (
                    <IconArrowUp
                      fill="none"
                      strokeWidth={2}
                      className="h-6 w-6"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      onClick={() => setIsActive(!isActive)}
                    />
                  ) : (
                    <IconArrowDown
                      fill="none"
                      strokeWidth={2}
                      className="h-6 w-6"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      onClick={() => setIsActive(!isActive)}
                    />
                  )}
                </div>
              </h2>
            </button>
          </div>
          {isActive ? (
            <div>
              {getItems()}
              <div className="flex flex-col py-5 border-b-2">
                <div className="py-5">
                  <div className="flex justify-between">
                    <div className="text-sm">Subtotal:</div>
                    <div className="font-bold">
                      ${order.total.toLocaleString("en-US")}
                    </div>
                  </div>
                  <div className="hidden md:flex justify-between">
                    <div className="text-sm">Estimated Delivery:</div>
                    <div className="">{getDeliveryDate()}</div>
                  </div>
                  <div className="flex md:hidden justify-between">
                    <div className="text-sm">ETA:</div>
                    <div className="">{getDeliveryDateM()}</div>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="text-xl uppercase font-bold">total</div>
                  <div className="font-bold text-xl">
                    ${order.total.toLocaleString("en-US")}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="flex flex-col md:flex-row items-center justify-between gap-5 mt-10">
          <Button
            class="ghost"
            text="back to cart"
            click={() => {
              navigate("/cart");
            }}
          />
          <Button
            click={handleComplete}
            text="complete order"
            class="rainbow"
          />
        </div>
      </div>
    </div>
  );
};

export default CompleteOrder;
