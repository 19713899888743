import React from 'react'

function DesktopAnimation() {

    return (
        <svg fill="none" width="1000" height="1200" viewBox="0 0 800 900"
            xmlns="http://www.w3.org/2000/svg">
            <linearGradient id="PSgrad_1" x1="160" y1="-69.9999" x2="893.567" y2="357.057" gradientUnits="userSpaceOnUse">
                <stop offset="0.0104167" stopColor="#FD880F" />
                <stop offset="0.489987" stopColor="#E93322" />
                <stop offset="1" stopColor="#9E1FCD" />
            </linearGradient>
            <path fill="url(#PSgrad_1)">
                <animate attributeName="d" values="M908.82 408.989C882.826 583.057 803.305 565.209 665.5 627C564.331 672.364 435 807.5 288.5 726.5C142 645.5 -74.2451 351.28 26.3014 177.128C126.848 2.97684 180.607 -50.5181 360.484 53.3335C540.36 157.185 975.214 44.1012 908.82 408.989Z;
                            M842,346.9c52,78,12.8,293.2-125,355c-101.2,45.4-360.7,36.3-439-69c-110-148-315.8-246.5-215-420  C163.5,38.7,249.8,42.3,467,26.9C805,2.9,748.9,207.2,842,346.9z;
                            M908.82 408.989C882.826 583.057 803.305 565.209 665.5 627C564.331 672.364 435 807.5 288.5 726.5C142 645.5 -74.2451 351.28 26.3014 177.128C126.848 2.97684 180.607 -50.5181 360.484 53.3335C540.36 157.185 975.214 44.1012 908.82 408.989Z;" keyTimes="0; 0.5; 1" dur="12.5s" repeatCount="indefinite" />
            </path>
        </svg>
    )
}

export default DesktopAnimation
