import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../../store/auth";

interface Props {
  component: JSX.Element;
}

function GuestRoute(props: Props) {
  const auth = useAuth();
  const { component } = props;

  if (auth.isLoggedIn) {
    return <Navigate to="/" />;
  }

  return component;
}

export default GuestRoute;
