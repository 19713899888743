import React from 'react';
import Icon from './icon';

function IconSearch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon width="28" height="29" viewBox="0 0 28 29" {...props}>
      <path
        d="M27.8359 26.1484C27.9453 26.2578 28 26.4219 28 26.5859C28 26.8047 27.9453 26.9688 27.8359 27.0781L26.5781 28.2812C26.4141 28.4453 26.25 28.5 26.0859 28.5C25.8672 28.5 25.7578 28.4453 25.6484 28.2812L18.9766 21.6641C18.8672 21.5547 18.8125 21.3906 18.8125 21.2266V20.4609C17.7734 21.3359 16.625 22.0469 15.3672 22.5391C14.0547 23.0312 12.7422 23.25 11.375 23.25C9.29688 23.25 7.38281 22.7578 5.63281 21.7188C3.88281 20.7344 2.51562 19.3672 1.53125 17.6172C0.492188 15.8672 0 13.9531 0 11.875C0 9.79688 0.492188 7.9375 1.53125 6.1875C2.51562 4.4375 3.88281 3.07031 5.63281 2.03125C7.38281 1.04688 9.29688 0.5 11.375 0.5C13.4531 0.5 15.3125 1.04688 17.0625 2.03125C18.8125 3.07031 20.1797 4.4375 21.2188 6.1875C22.2031 7.9375 22.75 9.79688 22.75 11.875C22.75 13.2969 22.4766 14.6094 21.9844 15.8672C21.4922 17.1797 20.8359 18.3281 19.9609 19.3125H20.7266C20.8906 19.3125 21.0547 19.3672 21.1641 19.4766L27.8359 26.1484ZM11.375 20.625C12.9062 20.625 14.3828 20.2422 15.75 19.4766C17.0625 18.7109 18.1562 17.6172 18.9219 16.25C19.6875 14.9375 20.125 13.4609 20.125 11.875C20.125 10.3438 19.6875 8.86719 18.9219 7.5C18.1562 6.1875 17.0625 5.09375 15.75 4.32812C14.3828 3.5625 12.9062 3.125 11.375 3.125C9.78906 3.125 8.3125 3.5625 7 4.32812C5.63281 5.09375 4.53906 6.1875 3.77344 7.5C3.00781 8.86719 2.625 10.3438 2.625 11.875C2.625 13.4609 3.00781 14.9375 3.77344 16.25C4.53906 17.6172 5.63281 18.7109 7 19.4766C8.3125 20.2422 9.78906 20.625 11.375 20.625Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default IconSearch;
