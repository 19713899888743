import React from "react";
import Header from "../app/header/header";
import Profile from "../../Containers/Profile/Profile";
import ProtectedRoute from "../global/auth/protectedRoute";

function ProfilePage() {
  return (
    <ProtectedRoute
      component={
        <>
          <Header />
          <Profile />
        </>
      }
    />
  );
}

export default ProfilePage;
