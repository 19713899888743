
import axios from 'axios';
import authHeader from './auth-header';
import eventBus from '../common/EventBus';

export const API_URL = process.env.REACT_APP_PROD_API_URL + '/api/v1/';

export const initAxiosInterceptors = () => {
    axios.interceptors.request.use(function (config) {
        config.baseURL = API_URL;
        // auth token
        const authHeaders = authHeader();
        if (authHeaders?.Authorization && config.headers) {
            config.headers['Authorization'] = authHeaders.Authorization;
        }
        return config;
    }, function (error) {
        return Promise.reject(error);
    });

    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            if (error.response) {
                if (error.response.status === 401 || error.response.status === 403) {
                    eventBus.dispatch('logout');
                }
            }
            return Promise.reject(error);
        },
    );
};