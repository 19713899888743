import React, { useEffect } from "react";
import Loading from "../loading/loading";
import { useAppDispatch } from "../../../store/store";
import { updateUser } from "../../../store/actions/user";
import { updateCart } from "../../../store/actions/cart";
import { useGetUserDataQuery } from "../../../services/new-user.service";
import { initialCart } from "../../../store/cart";
import eventBus from "../../../common/EventBus";
import { logout } from "../../../store/actions/auth";

interface Props {
  children: JSX.Element;
}

function AuthWrapper(props: Props) {
  const { children } = props;

  const dispatch = useAppDispatch();

  const { isLoading, data } = useGetUserDataQuery();

  const onLogout = () => {
    dispatch(logout())
  };

  // TODO improve auth 
  useEffect(() => {
    eventBus.on("logout", onLogout);
    return () => {
      eventBus.remove("logout", onLogout);
    };
  }, []);

  useEffect(() => {
    if (data) {
      dispatch(updateUser(data));
      dispatch(updateCart(data.carts[0] || initialCart));
    }
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }

  return children;
}

export default AuthWrapper;
