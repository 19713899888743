import React from "react";
import logo from "../../../favicon.png";
import { NavLink } from "react-router-dom";

function HeaderCheckout() {
  return (
    <div className="flex justify-center px-5 md:px-10 py-2 min-h-10 md:h-20 items-center  bg-white">
      <NavLink to="/">
        <img src={logo} alt="" className="max-w-[125px]" />
      </NavLink>
    </div>
  );
}

export default HeaderCheckout;
