import React from 'react'

function MobileAnimation() {

    return (
        <svg width="1000" height="800" viewBox="100 0 200 500" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <linearGradient id="PSgrad_0" x1="256.331" y1="-31.8848" x2="390.944" y2="477.838" gradientUnits="userSpaceOnUse">
                <stop offset="0.0104167" stopColor="#FD880F" />
                <stop offset="0.489987" stopColor="#E93322" />
                <stop offset="1" stopColor="#9E1FCD" />
            </linearGradient>
            <path fill="url(#PSgrad_0)">
                <animate attributeName="d" values="
                                M 374.835 507.344C286.972 572.375 259.886 529.612 172.228 496.229C107.874 471.72 -8.27492 474.27 -37.0412 374.356C-65.8074 274.443 -31.5624 50.2571 89.0793 17.9312C209.721 -14.3947 256.825 -14.2785 290.214 110.329C323.602 234.937 564.245 376.251 374.835 507.344Z;
                                M 842,346.9c52,78,12.8,293.2-125,355c-101.2,45.4-360.7,36.3-439-69c-110-148-315.8-246.5-215-420  C163.5,38.7,249.8,42.3,467,26.9C805,2.9,748.9,207.2,842,346.9z
                            ;
                            M 374.835 507.344C286.972 572.375 259.886 529.612 172.228 496.229C107.874 471.72 -8.27492
                            474.27 -37.0412 374.356C-65.8074 274.443 -31.5624 50.2571 89.0793 17.9312C209.721 -14.3947
                            256.825 -14.2785 290.214 110.329C323.602 234.937 564.245 376.251 374.835 507.344Z;" keyTimes="0; 0.5; 1" dur="12.5s" repeatCount="indefinite" />
            </path>
        </svg>
    )
}

export default MobileAnimation
