import React from 'react';
import Icon from './icon';

function IconCheckSuccess(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon
      width="102"
      fill="none"
      height="102"
      viewBox="0 0 102 102"
      {...props}>
      <circle cx="51" cy="51" r="51" fill="#FD880F" />
      <path
        d="M42.9141 65.3927C43.2109 65.8482 43.6562 66 44.25 66C44.7695 66 45.2148 65.8482 45.5859 65.3927L67.4805 43.0733C67.7773 42.7696 68 42.3141 68 41.7068C68 41.1754 67.7773 40.7199 67.4805 40.3403L64.7344 37.6073C64.3633 37.2277 63.918 37 63.3984 37C62.8789 37 62.4336 37.2277 62.0625 37.6073L44.25 55.8272L35.9375 47.3246C35.4922 46.945 35.0469 46.7173 34.5273 46.7173C34.0078 46.7173 33.5625 46.945 33.2656 47.3246L30.5195 50.0576C30.1484 50.4372 30 50.8927 30 51.4241C30 52.0314 30.1484 52.4869 30.5195 52.7906L42.9141 65.3927Z"
        fill="white"
      />
    </Icon>
  );
}

export default IconCheckSuccess;
