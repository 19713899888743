import React from 'react';
import Icon from './icon';

function IconOrders(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon
      width="45"
      height="37"
      viewBox="0 0 45 37"
      fill="none" {...props}>
      <path
        d="M34.5938 9.85156C37.6406 10.6016 40.125 12.1953 42.0469 14.6328C44.0156 17.0703 45 19.8594 45 23C45 26.75 43.6875 29.9375 41.0625 32.5625C38.4375 35.1875 35.25 36.5 31.5 36.5H3.375C2.4375 36.5 1.64062 36.1719 0.984375 35.5156C0.328125 34.8594 0 34.0625 0 33.125V14.5625C0 14.1875 0.046875 13.8359 0.140625 13.5078L3.72656 2.82031C3.96094 2.11719 4.35938 1.55469 4.92188 1.13281C5.53125 0.710938 6.21094 0.5 6.96094 0.5H29.0391C29.5547 0.5 30.0234 0.59375 30.4453 0.78125C30.8672 0.96875 31.2422 1.25 31.5703 1.625C31.8984 1.95312 32.1328 2.35156 32.2734 2.82031L34.5938 9.85156ZM19.125 2.75V14H21.4453C24.1641 11 27.5156 9.5 31.5 9.5C31.5938 9.5 31.7109 9.5 31.8516 9.5C31.9922 9.5 32.0859 9.5 32.1328 9.5L30.1641 3.52344C29.9766 3.00781 29.6016 2.75 29.0391 2.75H19.125ZM5.83594 3.52344L2.39062 14H16.875V2.75H6.96094C6.39844 2.75 6.02344 3.00781 5.83594 3.52344ZM3.375 34.25H24.0469C22.1719 33.0312 20.6953 31.4375 19.6172 29.4688C18.5391 27.4531 18 25.2969 18 23C18 20.6094 18.6094 18.3594 19.8281 16.25H2.25V33.125C2.25 33.4531 2.34375 33.7109 2.53125 33.8984C2.76562 34.1328 3.04688 34.25 3.375 34.25ZM23.5547 30.9453C25.7578 33.1484 28.4062 34.25 31.5 34.25C34.5938 34.25 37.2422 33.1484 39.4453 30.9453C41.6484 28.7422 42.75 26.0938 42.75 23C42.75 19.9062 41.6484 17.2578 39.4453 15.0547C37.2422 12.8516 34.5938 11.75 31.5 11.75C28.4062 11.75 25.7578 12.8516 23.5547 15.0547C21.3516 17.2578 20.25 19.9062 20.25 23C20.25 26.0938 21.3516 28.7422 23.5547 30.9453ZM36.0703 18.6406L37.3359 19.9766C37.6172 20.2578 37.6172 20.5156 37.3359 20.75L30.7266 27.3594C30.4453 27.5938 30.1875 27.5938 29.9531 27.3594L26.0859 23.4922C25.8516 23.2109 25.8516 22.9531 26.0859 22.7188L27.4219 21.3828C27.7031 21.1016 27.9609 21.1016 28.1953 21.3828L30.375 23.5625L35.2266 18.6406C35.5078 18.4062 35.7891 18.4062 36.0703 18.6406Z"
        fill="url(#paint0_linear_59_6087)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_59_6087"
          x1="-1.40438"
          y1="-36.6835"
          x2="42.7273"
          y2="-36.6445"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0104167" stopColor="#FD880F" />
          <stop offset="0.489987" stopColor="#E93322" />
          <stop offset="1" stopColor="#9E1FCD" />
        </linearGradient>
      </defs>
    </Icon>
  );
}

export default IconOrders;
