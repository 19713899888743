import React from "react";
import Cart from "../app/cart/cart";
import Header from "../app/header/header";
import ProtectedRoute from "../global/auth/protectedRoute";

function CartPage() {
  return (
    <ProtectedRoute
      component={
        <>
          <Header />
          <Cart />
        </>
      }
    />
  );
}

export default CartPage;
