import React from "react";
import { NavLink } from "react-router-dom";
import { useCart } from "../../../store/cart";
import IconCart from "../../global/icons/iconCart";
import IconSearch from "../../global/icons/iconSearch";
import IconAccount from "../../global/icons/iconAccount";

const iconStyles: React.CSSProperties = {
  color: "#20212A",
};

const HeaderNavigation = () => {
  const cartState = useCart();

  return (
    <div className="flex gap-5 justify-between items-center">
      <NavLink to="/cart">
        <div className="flex relative">
          <IconCart style={iconStyles} />
          {Boolean(cartState.uiCartItemsCount) && (
            <div className="bg-[#9E1FCD] text-white absolute bottom-0 right-0 translate-y-1/2 translate-x-2/4 rounded-full w-4 h-4 text-center align-middle text-xs">
              {cartState.uiCartItemsCount}
            </div>
          )}
        </div>
      </NavLink>
      <NavLink to="/search">
        <IconSearch style={iconStyles} />
      </NavLink>
      <NavLink to="/profile">
        <IconAccount style={iconStyles} />
      </NavLink>
    </div>
  );
};

export default HeaderNavigation;
