import React from "react";
import classes from "./button.module.scss";
import IconCircle from "../icons/iconCircle";

interface Props {
  text?: string;
  class?: string;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  click?: () => void;
}

function Button(props: Props) {
  return (
    <button
      className={`${classes["button"]} h-10 md:h-12 ${classes[`${props.class}`]
        } ${props.className}`}
      onClick={() => {
        if (props.click) props.click();
      }}
      disabled={props.disabled || props.loading}
    >
      {props.loading ? <IconCircle className="animate-spin h-6 w-6 m-auto" /> : props.text}
    </button>
  );
}

export default Button;