import React, { useEffect, useState } from "react";
import ProfileLanding from "./ProfileLanding/ProfileLanding";
import Orders from "./Orders/Orders";
import UserService from "../../services/user.service";
import AddressBook from "./AddressBook/AddressBook";
import EditProfile from "./EditProfile/EditProfile";
import { useParams } from "react-router-dom";
import { history } from "../../libs/browser-history";
import EventBus from "../../common/EventBus";
import Loading from "../../component/global/loading/loading";
import { useAppDispatch } from "../../store/store";
import { logout } from "../../store/actions/auth";

const Profile = (props) => {
  const dispatch = useAppDispatch();
  const [activeView, setActiveView] = useState("landing");
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const productSlug = useParams().page;

  const handleLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    if (productSlug === undefined) {
      setActiveView("landing");
    }
    if (productSlug === "order-history") {
      setActiveView("orders");
    }
    if (productSlug === "addresses") {
      setActiveView("address");
    }
    if (productSlug === "edit-profile") {
      setActiveView("edit");
    }
    UserService.getUserData().then((res) => {
      setUserData(res.data.data);
      setIsLoading(false);
    });
    UserService.getUserData().then(
      (response) => {
        if (response.data.status === "Authorization Token not found") {
          EventBus.dispatch("logout");
        }
      },
      (error) => {
        console.log(error);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  const views = {
    landing: (
      <ProfileLanding setActiveView={setActiveView} userData={userData} />
    ),
    orders: <Orders setActiveView={setActiveView} />,
    address: <AddressBook setActiveView={setActiveView} />,
    edit: (
      <EditProfile
        userData={userData}
        setUserData={setUserData}
        setActiveView={setActiveView}
      />
    ),
  };

  if (activeView === "landing") {
    return <>{isLoading ? <Loading /> : views[activeView]}</>;
  }
  return (
    <div className="md:grid md:grid-cols-[1fr_4fr] px-5 md:px-10">
      <div className="flex flex-col pt-20 hidden md:block">
        <div
          className={
            activeView === "orders"
              ? "uppercase cursor-pointer mb-5 font-bold"
              : "uppercase cursor-pointer mb-5 hover:font-bold"
          }
          onClick={() => {
            history.push("/profile/order-history");
            setActiveView("orders");
          }}
        >
          orders
        </div>
        <div
          className={
            activeView === "address"
              ? "uppercase cursor-pointer mb-5 font-bold"
              : "uppercase cursor-pointer mb-5 hover:font-bold"
          }
          onClick={() => {
            history.push("/profile/addresses");
            setActiveView("address");
          }}
        >
          address book
        </div>
        <div
          className={
            activeView === "edit"
              ? "uppercase cursor-pointer mb-10 font-bold"
              : "uppercase cursor-pointer mb-10 hover:font-bold"
          }
          onClick={() => {
            history.push("/profile/edit-profile");
            setActiveView("edit");
          }}
        >
          edit profile
        </div>
        <div
          className="uppercase hover:font-bold cursor-pointer"
          onClick={handleLogout}
        >
          logout
        </div>
      </div>
      {views[activeView]}
    </div>
  );
};

export default Profile;
