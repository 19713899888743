import auth from './auth';
import cart from './cart';
import user from './user';
import message from './message';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';
import { useDispatch } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

export const rootReducer = combineReducers({
  auth,
  cart,
  user,
  message,
});

const middleware = [thunk];
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
