import axios from 'axios';
import { useQuery } from 'react-query';
import { UserDataType } from '../component/app/user/types';
// API
export const getUserData = async (): Promise<UserDataType> => {
  return axios.get(`user-data`).then((res) => res.data.data);
};

// Query
export const useGetUserDataQuery = () => {
  return useQuery('getUserData', () => getUserData(), { retry: false });
};
