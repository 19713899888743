import React from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./productSwiper.scss";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/scrollbar";

interface Props {
  images: string[];
  desktopImageCount?: number;
}

const ProductSwiper = (props: Props) => {
  const { images } = props;

  return (
    <div className="px-4">
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        loop={true}
        preventClicks
        spaceBetween={50}
        slidesPerView={1}
        allowTouchMove={false}
        preventClicksPropagation
        className="swiper-product"
        navigation={images.length > 1}
      >
        {images.map((image) => (
          <SwiperSlide
            key={`swiper-item-${image}`}
            className="grid justify-center"
          >
            <img
              alt=""
              src={image}
              data-bs-toggle="modal"
              data-bs-target="#exampleModalFullscreen"
              className="mw-100 max-h-[700px] md:max-h-[700px] my-auto"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
export default ProductSwiper;
