import { isDev } from "../env-mode";

export enum LoggerLevel {
    ERROR,
    WARNING,
    DEBUG,
    INFO
}

export interface Logger {
    isActive(): boolean

    setLevel(level: LoggerLevel): void;

    getLevel(): LoggerLevel;

    error(log: any): void;

    info(log: any): void;

    warn(log: any): void;

    log(log: any): void;
}

export class LoggerImpl implements Logger {
    private active: boolean = true
    private level: LoggerLevel = LoggerLevel.INFO

    constructor(active: boolean, level: LoggerLevel = LoggerLevel.INFO) {
        this.active = active
        this.level = level
    }

    isActive(): boolean {
        return this.active
    }

    setLevel(level: LoggerLevel): void {
        this.level = level
    }

    getLevel(): LoggerLevel {
        return this.level
    }

    error(log: any): void {
        this._log(log, LoggerLevel.ERROR, console.error)
    }

    info(log: any): void {
        this._log(log, LoggerLevel.INFO, console.info)
    }

    warn(log: any): void {
        this._log(log, LoggerLevel.WARNING, console.warn)
    }

    log(log: any): void {
        this._log(log, LoggerLevel.DEBUG, console.log)
    }

    private _log(log: any, level: LoggerLevel, callback: any) {
        if (!this.active) {
            return
        }

        if (level > this.level) {
            return
        }

        callback(log)
    }
}

const loggerFactory = () => {
    const logger = new LoggerImpl(isDev)
    // register globally
    if (typeof window !== "undefined") {
        (window as any).appLogger = logger;
    }
    return logger
}

export const logger = loggerFactory()
