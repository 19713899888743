import React from "react";
import AuthWrapper from "./authWrapper";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../../store/auth";

interface Props {
  component: JSX.Element;
}

function ProtectedRoute(props: Props) {
  const auth = useAuth();
  const { component } = props;

  if (!auth.isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return <AuthWrapper>{component}</AuthWrapper>;
}

export default ProtectedRoute;
