export const formatEstimatedDeliveryDate = (date: Date): string => {
  return date.toLocaleDateString("en-US", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  })
};

export const formaDeliveryDate = (date: string): string => {
  const _date = new Date(date);
  const formattedDate = _date.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
  return formattedDate;
};
