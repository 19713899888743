import React from 'react';
import Icon from './icon';

function IconPencil(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon
      fill="none"

      width="12"
      height="12"
      viewBox="0 0 12 12" {...props}>
      <path
        d="M11.5318 1.73229C11.8361 2.06002 12 2.43457 12 2.85593C12 3.30071 11.8361 3.67526 11.5318 3.97958L4.15789 11.4003L0.669905 11.9856C0.48263 12.0324 0.318765 11.9621 0.17831 11.8217C0.0378539 11.6812 -0.032374 11.5174 0.0144446 11.3301L0.599677 7.84211L8.02042 0.468186C8.32474 0.163865 8.69929 0 9.14407 0C9.56543 0 9.93998 0.163865 10.2677 0.468186L11.5318 1.73229ZM2.84697 7.0696H3.80675V8.19325H4.9304V9.15303L8.88657 5.17345L6.82655 3.11344L2.84697 7.0696ZM1.93401 10.7215L3.66629 10.4405L4.08766 10.0192V9.03598H2.96402V7.91234H1.98082L1.55946 8.33371L1.27855 10.066L1.93401 10.7215ZM10.8061 3.25389C10.8998 3.16025 10.97 3.0198 10.97 2.85593C10.97 2.71548 10.8998 2.57502 10.7827 2.45798L9.54202 1.19387C9.42498 1.10024 9.28452 1.03001 9.14407 1.03001C8.9802 1.03001 8.83975 1.10024 8.74611 1.19387L7.62246 2.31752L9.68248 4.37754L10.8061 3.25389Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default IconPencil;
