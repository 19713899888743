import "./login.scss";
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { login } from "../../store/actions/auth";
import { history } from "../../libs/browser-history";
import { useDispatch, useSelector } from "react-redux";
import CheckButton from "react-validation/build/button";
import Button from "../../component/global/button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import RequiredFieldWhite from "../../component/global/fileds/requiredFieldWhite";
import RequiredEmailWhite from "../../component/global/fileds/requiredEmailWhite";
import PasswordFieldLength from "../../component/global/fileds/passwordFieldLength";
import DesktopAnimation from "../../component/global/animation/desktopAnimation";
import MobileAnimation from "../../component/global/animation/mobileAnimation";
import { clearMessage } from "../../store/actions/message";

const Login = () => {
  const form = useRef();
  const checkBtn = useRef();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [remember, setRemember] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const onChangeEmail = (e) => {
    const email = e.target.value.trim();
    setEmail(email);
  };
  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  const onChangeRemember = (e) => {
    setRemember(!remember);
  };
  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(clearMessage());
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      setLoading(true);
      dispatch(login(email, password, remember))
        .then(() => {
          history.push("/");
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  if (isLoggedIn) {
    navigate("/");
  }
  const signUp = () => {
    navigate("/register");
  };
  const forgot = () => {
    navigate("/forgot-password");
  };
  return (
    <>
      <div className="container overflow-hidden w-full mx-auto px-5 md:px-10 text-center login-page flex md:items-center md:justify-center">
        <div className="mobile ">
          <MobileAnimation />
        </div>
        <div className=" wrapper">
          <DesktopAnimation />
        </div>
        <div className="w-full flex flex-col mt-12 md:mt-0 ">
          <Form
            onSubmit={handleLogin}
            ref={form}
            className="w-full mt-20 flex-col md:items-center md:justify-center mb-6"
          >
            <div className="md:w-1/3  mx-auto">
              <h3 className="uppercase text-2xl text-white font-bold mb-8">
                Login
              </h3>
              <div className="items-center justify-center w-full mb-6">
                <fieldset className="border border-white">
                  <legend className="text-left ml-4 px-3 text-white uppercase">
                    Email address
                  </legend>
                  <Input
                    type=" text"
                    className=" form-control custom-input m-2 text-white"
                    name="email"
                    value={email}
                    onChange={onChangeEmail}
                    validations={[RequiredFieldWhite, RequiredEmailWhite]}
                  />
                </fieldset>
              </div>
              <div className="items-center justify-center w-full mb-6">
                <fieldset className="border border-white text-white">
                  <legend className="text-left ml-4 px-3 text-white uppercase">
                    Password
                  </legend>
                  <div className="relative">
                    <Input
                      type={passwordShown ? "text" : "password"}
                      className=" form-control custom-input m-2 text-white"
                      name="email"
                      value={password}
                      onChange={onChangePassword}
                      validations={[RequiredFieldWhite, PasswordFieldLength]}
                    />
                    <FontAwesomeIcon
                      icon={passwordShown ? solid("eye") : solid("eye-slash")}
                      className="absolute right-4 inset-y-1/4"
                      style={{ path: "white" }}
                      onClick={() => {
                        setPasswordShown(!passwordShown);
                      }}
                    />
                  </div>
                </fieldset>
              </div>
              <div className="flex align-center items-center mb-5">
                <input
                  type="checkbox"
                  name="remember"
                  id="remember"
                  className="remember"
                  onChange={onChangeRemember}
                />
                <label htmlFor="remember" className="uppercase ">
                  {" "}
                  Remember me
                </label>
              </div>
              <div className="flex w-full justify-center">
                <Button
                  className="btn btn-primary btn-block"
                  loading={loading}
                  class="small"
                  text="login"
                ></Button>
              </div>
              {message && (
                <div className="form-group">
                  <div
                    className="text-white uppercase my-4 font-bold w-full text-lg"
                    role="alert"
                  >
                    {message}
                  </div>
                </div>
              )}
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </div>
          </Form>
          <div className="flex flex-col items-center">
            <h3
              className="text-white text-xl font-bold uppercase mb-6 cursor-pointer"
              onClick={() => {
                forgot();
              }}
            >
              Forgot Your password?
            </h3>

            <h4 className="text-white  text uppercase border-t border-[#ffffff40] pt-4">
              Don’t have an Account?{" "}
              <strong onClick={signUp} className="cursor-pointer">
                Sign Up
              </strong>
              .
            </h4>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
