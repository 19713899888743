import store from "../store/store";

export default function authHeader() {
  const token = store?.getState()?.auth?.user?.token;

  if (token) {
    return { Authorization: `Bearer ${token}` };
  }

  return {};
}
