import "./Home.scss";
import React, { useEffect, useState } from "react";
import ProductFilter from "../Product/ProductFilter/ProductFilter";
import UserService from "../../services/user.service";
import EventBus from "../../common/EventBus";
import Loading from "../../component/global/loading/loading";
import Button from "../../component/global/button/Button";
import { logout } from "../../store/actions/auth";
import { useAppDispatch } from "../../store/store";
import ProductCard from "../../component/app/product/card/productCard";

const Home = () => {
  const [products, setProducts] = useState([]);
  const dispatch = useAppDispatch();
  let [limit, setLimit] = useState(8);
  const [filters, setFilters] = useState(null);
  const [filter, setFilter] = useState({});
  let [params, setParams] = useState();
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [noClientModal, setNoClientModal] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    UserService.getProductFilters().then(
      (response) => {
        setFilters(response.data);
        UserService.getProducts().then(
          (response) => {
            setProducts(response.data.data);
            setTotal(response.data.meta.total);
            setIsLoading(false);
          },
          (error) => {
            console.log(error);
            if (error.response && error.response.status === 401) {
              EventBus.dispatch("logout");
            }
          }
        );
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    filters &&
      filters.brands.length === 0 &&
      products.length === 0 &&
      setNoClientModal(true);
  }, [products]);

  function getMoreProducts(filter) {
    UserService.queryProducts(filter).then(
      (response) => {
        setProducts(response.data.data);
      },
      (error) => {
        console.log(error);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="px-5 md:px-10">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="sm:flex flex-col justify-around  py-2 md:py-5 md:grid md:grid-cols-[1fr_4fr]">
          {filters && (
            <ProductFilter
              params={params}
              setParams={setParams}
              setLimit={setLimit}
              filters={filters}
              setTotal={setTotal}
              setFilter={setFilter}
              setProducts={setProducts}
            />
          )}
          <div className="products mt-2 md:mt-0">
            <div className="grid justify-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {products.map((product) => (
                <ProductCard
                  product={product}
                  key={`product-list-item-${product.id}`}
                />
              ))}
            </div>
            <div className="flex justify-center my-20">
              {products.length !== 0 && limit < total ? (
                <Button
                  text="Load More"
                  class="rainbow"
                  click={() => {
                    setLimit((limit += 8));
                    getMoreProducts({ ...filter, limit: limit });
                  }}
                />
              ) : (
                products.length === 0 &&
                !isLoading &&
                filters.brands.length !== 0 && (
                  <div className="text-center font-bold text-2xl">
                    There are no products that match your filtering. Please
                    unselect one of the filters and try again.
                  </div>
                )
              )}
            </div>
            {noClientModal && (
              <div
                tabIndex="-1"
                className="block overflow-y-auto w-full overflow-x-hidden fixed top-0 bottom-0 bg-gray-500/90 right-0 left-0 z-50 md:inset-0 h-modal flex justify-center items-center md:h-full"
              >
                <div className="relative md:p-8 w-full h-full md:h-auto md:flex items-center justify-center">
                  <div className="relative bg-white h-full md:w-[700px] shadow-xl dark:bg-gray-700">
                    <div className="px-20 py-6 text-center">
                      <h3 className="mb-5 text-2xl uppercase font-bold font-normal dark:text-gray-400">
                        There is no client in your profile
                      </h3>

                      <div className="mt-20">
                        As of this time, we don't have a company associated with
                        your email address.
                      </div>
                      <div className="mt-5">
                        Please contact your sales leader or contact 3D Color at{" "}
                        <strong> 513-221-6863</strong> or a{" "}
                        <strong> chat</strong> via this app.
                      </div>
                      <div className="mt-5 mb-10">
                        We should be able to get you going in a few minutes!
                      </div>
                      <Button
                        text={"Log Out"}
                        class={"rainbow"}
                        click={handleLogout}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default Home;
