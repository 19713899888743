import React from "react";
import { useNavigate } from "react-router-dom";
import IconArrowLeft from "../../../global/icons/iconArrowLeft";

function BackButton() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <button
      onClick={handleClick}
      className="flex items-center mt-6 mb-2 md:my-4 text-lg text-purple-800 stroke-purple-800 hover:text-pink-700 hover:stroke-pink-700"
    >
      <IconArrowLeft className="h-4 w-4" stroke="purple-800" strokeWidth={5} />
      <strong className="ml-3"> BACK</strong>
    </button>
  );
}

export default BackButton;
