import axios from 'axios';
import { useQuery } from 'react-query';
import { ProductType } from '../component/app/product/types';

// API
export const getProduct = async (productSlug: string): Promise<ProductType> => {
    return axios.get(`products-by-slug/${productSlug}`).then(res => res.data?.data)
};

// Query
export const useGetProductQuery = (productSlug: string) => {
    return useQuery('getProductBySlug', () => getProduct(productSlug), { retry: false, staleTime: 0 });
};
