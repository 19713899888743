import "./App.css";
import "./scss/global.scss";
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { initAxiosInterceptors } from "./services/axios.service";
// pages
import CartPage from "./component/pages/cartPage";
import HomePage from "./component/pages/homePage";
import LoginPage from "./component/pages/loginPage";
import ProfilePage from "./component/pages/profilePage";
import CheckoutPage from "./component/pages/checkoutPage";
import RegisterPage from "./component/pages/registerPage";
import PasswordResetPage from "./component/pages/passwordResetPage";
import SearchProductPage from "./component/pages/searchProductPage";
import ProductDetailsPage from "./component/pages/productDetailsPage";
import ForgotPasswordPage from "./component/pages/forgotPasswordPage";

// Axios navigator
initAxiosInterceptors();

const App = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/cart" element={<CartPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/profile/:page" element={<ProfilePage />} />
      <Route path="/checkout" element={<CheckoutPage />} />
      <Route path="/search" element={<SearchProductPage />} />
      <Route path="/products/:productSlug" element={<ProductDetailsPage />} />
      {/* Guest routes */}
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/password-reset" element={<PasswordResetPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
    </Routes>
  );
};

export default App;
