import { RootState } from './store';
import { useSelector } from 'react-redux';
import { UserActions } from './actions/user';
import { UserDataType } from '../component/app/user/types';

export const initialUserDataState: UserDataType = {
  id: -1,
  city: '',
  phone: '',
  email: '',
  state: '',
  carts: [],
  zipcode: '',
  address: '',
  address2: '',
  last_name: '',
  first_name: '',
  client: {
    id: -1,
    name: '',
    slug: '',
    status: false,
    created_at: '',
    mail_domain: '',
  },
  customerAddresses: [],
};

export const UserReducer = (state: UserDataType = initialUserDataState, action: UserActions): UserDataType | undefined => {
  switch (action.type) {
    case 'UPDATE_USER':
      return {
        ...action.payload,
      };
    case 'CLEAR_USER':
      return {
        ...initialUserDataState,
      };
    default:
      return state;
  }
};

export const useUserData = (): UserDataType => {
  return useSelector((store: RootState) => store.user || initialUserDataState);
};

export default UserReducer;
