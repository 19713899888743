export interface AuthType {
    success: boolean,
    token: string | null,
    isLoggedIn: boolean,
}

export const initialAuth: AuthType = {
    isLoggedIn: false,
    success: false,
    token: null
}

const parseAuth = (): AuthType => {
    try {
        const auth = JSON.parse(localStorage.getItem("user") || "")

        if (auth?.token) {
            return {
                ...auth,
                isLoggedIn: true
            }
        }

        return initialAuth
    } catch (error) {
        return initialAuth
    }
}

export const getAuth = () => {
    const auth = parseAuth()
    return {
        user: {
            token: auth.token,
            success: auth.success
        },
        isLoggedIn: auth.isLoggedIn
    }
}

export const setAuth = (auth: AuthType) => {
    localStorage.setItem("user", JSON.stringify(auth))
}

export const clearAuth = () => {
    localStorage.removeItem("user")
}