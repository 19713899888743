import React from "react";

const RequiredFieldWhite = (value?: string) => {
  if (!value?.trim()) {
    return (
      <div className="text-white absolute" role="alert">
        This field is required!
      </div>
    );
  }
};

export default RequiredFieldWhite;
