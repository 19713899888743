import { CartPackageType, CartStateType, CartType, mapUICartItems, UICartPackageType } from '../component/app/cart/types';
import { CartActions, CartItemUpdateQuantityPayloadType } from './actions/cart';
import { useSelector } from 'react-redux';
import { RootState } from './store';

export const initialCart: CartType = {
  id: -1,
  total: 0,
  items: [],
  estimated_delivery: '',

};

export const initialCartState: CartStateType = {
  uiCartItems: [],
  cart: initialCart,
  uiCartItemsCount: 0
};

export const CartReducer = (state: CartStateType = initialCartState, action: CartActions): CartStateType | undefined => {
  switch (action.type) {
    case 'UPDATE_CART':
      return updateCart(action.payload)
    case 'ADD_CART_ITEM':
      return addToCart(state, action.payload)
    case 'CLEAR_CART':
      return {
        ...initialCartState,
      };
    case 'UPDATE_CART_ITEM_QUANTITY':
      return updateCartItem(state, action.payload)
    case 'REMOVE_CART_ITEM':
      return removeCartItem(state, action.payload)
    default:
      return state;
  }
};

export const useCart = (): CartStateType => {
  return useSelector((store: RootState) => store.cart || initialCartState);
};

export default CartReducer;

// Helpers
const updateCart = (cart: CartType) => {
  const uiCartItems = mapUICartItems(cart)
  return {
    cart: cart,
    uiCartItems,
    uiCartItemsCount: uiCartItems.length
  };
}

const addToCart = (state: CartStateType, cartItem: CartPackageType) => {
  const index = state.cart.items.findIndex(_cartItem => _cartItem.id === cartItem.id)
  const newCart: CartType = {
    ...state.cart,
  }

  if (index < 0) {
    newCart.items.push(cartItem)
  } else {
    newCart.items[index] = cartItem
  }

  const uiCartItems = mapUICartItems(newCart)

  return {
    uiCartItems,
    cart: newCart,
    uiCartItemsCount: uiCartItems.length
  };
}

const removeCartItem = (state: CartStateType, cartPackageType: UICartPackageType) => {
  const index = state.cart.items.findIndex(cartItem => cartItem.id === cartPackageType.id)

  if (index < 0) {
    return state
  }

  const newCart: CartType = {
    ...state.cart,
  }

  newCart.items[index].quantity = 0

  const uiCartItems = mapUICartItems(newCart)

  return {
    uiCartItems,
    cart: newCart,
    uiCartItemsCount: uiCartItems.length
  };
}

const updateCartItem = (state: CartStateType, payload: CartItemUpdateQuantityPayloadType) => {
  const index = state.cart.items.findIndex(cartItem => cartItem.id === payload.cartPackageType.id)

  if (index < 0) {
    return state
  }

  const newCart: CartType = {
    ...state.cart,
  }

  newCart.items[index].quantity = payload.quantity

  const uiCartItems = mapUICartItems(newCart)

  return {
    uiCartItems,
    cart: newCart,
    uiCartItemsCount: uiCartItems.length
  };
}