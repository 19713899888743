import React from "react";
import Header from "../app/header/header";
import ProductDetail from "../app/product/productDetail";
import ProtectedRoute from "../global/auth/protectedRoute";

function ProductDetailsPage() {
  return (
    <ProtectedRoute
      component={
        <>
          <Header />
          <ProductDetail />
        </>
      }
    />
  );
}

export default ProductDetailsPage;
