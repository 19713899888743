import React, { useState } from 'react';
import { Navigation, Thumbs, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';
import 'swiper/css/thumbs';

const DetailsSlider = (props) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const getSlides = () => {
    if (props.images)
      return props.images.map((image) => (
        <SwiperSlide key={Math.random()} className="grid justify-center">
          <img src={image} className="w-auto max-h-[400px] lg:max-h-[600px] xl:max-h-[900px] my-auto" alt="" />
        </SwiperSlide>
      ));
  };

  return (
    <div className="px-4">
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        height={800}
        loop={props?.images?.length > 1 ? true : false}
        navigation
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
      >
        {getSlides()}
      </Swiper>
      <div className="w-full h-40 flex justify-center absolute bottom-0 left-0">
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper"
        >
          {getSlides()}
        </Swiper>
      </div>
    </div>
  );
};
export default DetailsSlider;
