import "./Register.scss";
import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { register } from "../../store/actions/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Button from "../../component/global/button/Button";
import RequiredFieldWhite from "../../component/global/fileds/requiredFieldWhite";
import RequiredEmailWhite from "../../component/global/fileds/requiredEmailWhite";
import { useNavigate } from "react-router-dom";
import DesktopAnimation from "../../component/global/animation/desktopAnimation";
import MobileAnimation from "../../component/global/animation/mobileAnimation";
import IconCheckSuccess from "../../component/global/icons/iconCheckSuccess";
import { clearMessage } from "../../store/actions/message";

let passwordValue = "";
const vpassword = (value) => {
  passwordValue = value;
  if (value.length < 6) {
    return (
      <div className="text-white absolute" role="alert">
        The password must be at least 6 characters.
      </div>
    );
  }
};
const vpasswordConfirm = (value) => {
  if (value !== passwordValue) {
    return (
      <div className="text-white absolute" role="alert">
        The passwords are not matching.
      </div>
    );
  }
};

const Register = () => {
  const form = useRef();
  const checkBtn = useRef();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordConfirmShown, setPasswordConfirmShown] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const onChangePasswordConfirmation = (e) => {
    const passwordConfirm = e.target.value;
    setPasswordConfirmation(passwordConfirm);
  };
  const onChangeFirstName = (e) => {
    const fname = e.target.value;
    setFirstName(fname);
  };
  const onChangeLastName = (e) => {
    const lname = e.target.value;
    setLastName(lname);
  };
  const onChangeEmail = (e) => {
    const email = e.target.value.trim();
    setEmail(email);
  };
  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  const handleRegister = (e) => {
    e.preventDefault();
    setSuccessful(false);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      setLoading(true);
      dispatch(register(firstName, lastName, email, password))
        .then(() => {
          setSuccessful(true);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setSuccessful(false);
        });
    }
  };

  const navigateToLogin = () => {
    if (successful) {
      navigate("/login");
    }
    dispatch(clearMessage());
  };

  const toLogin = () => {
    navigate("/login");
  };

  return (
    <div className="container w-full mx-auto text-center register-page flex md:items-center justify-center">
      <div className="mobile ">
        <MobileAnimation />
      </div>
      <div className=" wrapper">
        <DesktopAnimation />
      </div>
      <div className="w-full flex mt-12 md:-mt-6 md:items-center justify-center">
        <Form
          ref={form}
          onSubmit={handleRegister}
          className="w-full flex flex-col md:items-center justify-center"
        >
          {!successful && (
            <div className="md:w-1/3 mx-5">
              <h3 className="uppercase text-2xl text-white font-bold mb-6">
                Sign up
              </h3>

              <div className="items-center justify-center w-full mb-6">
                <fieldset className="border border-white">
                  <legend className="text-left ml-4 px-3 text-white uppercase">
                    First Name
                  </legend>
                  <Input
                    type=" text"
                    className=" form-control custom-input m-1 text-white"
                    name="first_name"
                    value={firstName}
                    onChange={onChangeFirstName}
                    validations={[RequiredFieldWhite]}
                  />
                </fieldset>
              </div>
              <div className="items-center justify-center w-full mb-6">
                <fieldset className="border border-white">
                  <legend className="text-left ml-4 px-3 text-white uppercase">
                    Last Name
                  </legend>
                  <Input
                    type=" text"
                    className=" form-control custom-input m-1 text-white"
                    name="last_name"
                    value={lastName}
                    onChange={onChangeLastName}
                    validations={[RequiredFieldWhite]}
                  />
                </fieldset>
              </div>
              <div className="items-center justify-center w-full">
                <fieldset className="border border-white">
                  <legend className="text-left ml-4 px-3 text-white uppercase">
                    Email address
                  </legend>
                  <Input
                    type=" text"
                    className=" form-control custom-input m-1 text-white"
                    name="email"
                    value={email}
                    onChange={onChangeEmail}
                    validations={[RequiredFieldWhite, RequiredEmailWhite]}
                  />
                </fieldset>
              </div>

              <div className="">
                <fieldset className="border border-white my-5">
                  <legend className="text-left ml-4 px-3 text-white uppercase">
                    password
                  </legend>
                  <div className="relative">
                    <Input
                      type={passwordShown ? "text" : "password"}
                      className=" form-control custom-input m-1 text-white"
                      name="password"
                      value={password}
                      onChange={onChangePassword}
                      validations={[RequiredFieldWhite, vpassword]}
                    />{" "}
                    <FontAwesomeIcon
                      icon={passwordShown ? solid("eye") : solid("eye-slash")}
                      color="white"
                      className="absolute right-4 inset-y-1/4"
                      onClick={() => {
                        setPasswordShown(!passwordShown);
                      }}
                    />
                  </div>
                </fieldset>
              </div>
              <div className="mb-6">
                <fieldset className="border border-white">
                  <legend className="text-left ml-4 px-3 text-white uppercase">
                    repeat Password
                  </legend>
                  <div className="relative">
                    <Input
                      type={passwordConfirmShown ? "text" : "password"}
                      className=" form-control custom-input  m-2 text-white"
                      name="password_confirmation"
                      value={passwordConfirmation}
                      onChange={onChangePasswordConfirmation}
                      validations={[RequiredFieldWhite, vpasswordConfirm]}
                    />
                    <FontAwesomeIcon
                      icon={
                        passwordConfirmShown ? solid("eye") : solid("eye-slash")
                      }
                      className="absolute right-4 inset-y-1/4"
                      color="white"
                      onClick={() => {
                        setPasswordConfirmShown(!passwordConfirmShown);
                      }}
                    />
                  </div>
                </fieldset>
              </div>
              <div className="form-group mb-6">
                <Button
                  className="btn btn-primary btn-block"
                  loading={loading}
                  text="Sign up"
                  class="small"
                />
              </div>
              {message && (
                <div className="text-xl font-bold uppercase mt-2 text-white">
                  {message}
                </div>
              )}
              <h4 className="text-white text uppercase border-t border-[#ffffff40] pt-6">
                Already have an Account?{" "}
                <strong onClick={toLogin} className="cursor-pointer">
                  Log In
                </strong>
                .
              </h4>
            </div>
          )}

          <div className="md:w-1/3 mx-5">
            <div
              className={
                successful
                  ? "text-xl alert text-white uppercase font-bold"
                  : " alert alert-danger"
              }
            >
              {successful && (
                <div className="flex flex-col items-center text-white">
                  <div className="flex mb-6">
                    <IconCheckSuccess />
                  </div>
                  <h3 className="text-white mb-16">
                    you’ve registered successfully
                  </h3>
                  <Button text="login" click={navigateToLogin} class="small" />
                </div>
              )}
            </div>
          </div>
          <CheckButton style={{ display: " none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};
export default Register;
