import React, { useEffect, useState } from "react";
import "../../Home/Home.scss";
import UserService from "../../../services/user.service";
import EventBus from "../../../common/EventBus";
import Loading from "../../../component/global/loading/loading";
import Button from "../../../component/global/button/Button";
import ProductCard from "../../../component/app/product/card/productCard";

const SearchProduct = () => {
  const [products, setProducts] = useState([]);
  let [limit, setLimit] = useState(8);
  const [filter, setFilter] = useState({});
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setIsLoading(true);
    UserService.getUserData().then(
      (response) => {
        if (response.data.status === "Authorization Token not founds") {
          EventBus.dispatch("logout");
        }
      },
      (error) => {
        console.log(error);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
    UserService.getProducts().then(
      (response) => {
        setProducts(response.data.data);
        setTotal(response.data.meta.total);
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  function getMoreProducts(filter) {
    UserService.queryProducts(filter).then(
      (response) => {
        setProducts(response.data.data);
      },
      (error) => {
        console.log(error);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    let local = { ...filter, search: e.target.value };
    UserService.queryProducts(local).then(
      (response) => {
        setProducts(response.data.data);
        setTotal(response.data.meta.total);
        setFilter(local);
        setLimit(8);
      },
      (error) => {
        console.log(error);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  return (
    <div className="px-5 md:px-10">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col justify-center  py-5">
          <div className="items-center justify-center mb-6 w-full flex">
            <fieldset className="border-2 border-gray-500 w-1/2">
              <legend className="text-left ml-4 px-3 font-bold uppercase">
                Search
              </legend>
              <div className="relative ">
                <input
                  type="text"
                  className="form-control custom-input m-1 "
                  name="phone"
                  value={search}
                  onChange={onChangeSearch}
                />
              </div>
            </fieldset>
          </div>
          <div className="products">
            <div className="grid justify-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {products.map((product) => (
                <ProductCard
                  product={product}
                  key={`product-list-item-${product.id}`}
                />
              ))}
            </div>
            <div className="flex justify-center my-20">
              {products.length !== 0 && limit < total ? (
                <Button
                  text="Load More"
                  class="rainbow"
                  click={() => {
                    setLimit((limit += 8));
                    getMoreProducts({ ...filter, limit: limit });
                  }}
                />
              ) : (
                products.length === 0 &&
                !isLoading && (
                  <div className="text-center font-bold text-2xl">
                    There are no products that match your searching. Please try
                    again with an alternative search term.
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default SearchProduct;
