import React, { useEffect, useState } from "react";
import Button from "../../../component/global/button/Button";
import classes from "../../../component/global/button/button.module.scss";
import IconArrowDown from "../../../component/global/icons/iconArrowDown";
import IconArrowUp from "../../../component/global/icons/iconArrowUp";
import IconBlock from "../../../component/global/icons/iconBlock";
import IconCheckOutline from "../../../component/global/icons/iconCheckOutline";
import IconPackageChecked from "../../../component/global/icons/iconPackageChecked";
import IconPencil from "../../../component/global/icons/iconPencil";
import IconTrack from "../../../component/global/icons/iconTrack";

const OrderDetails = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [orderItems, setOrderItems] = useState([]);
  const [order] = useState(props.order);
  useEffect(() => {
    getOrderItems();
  }, [props.order]);

  const getDeliveryDate = () => {
    const date = new Date(order.estimated_delivery);
    const formattedDate = date.toLocaleDateString("en-US", {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return formattedDate;
  };
  const getDeliveryDateM = () => {
    const date = new Date(order.estimated_delivery);
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return formattedDate;
  };
  const getOrderDate = () => {
    const date = new Date(order.order_date);
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });
    return formattedDate;
  };
  const getOrderItems = () => {
    let local = [];
    props.order.order_items.forEach((y) => {
      if (
        local.filter((copy) => copy.product_id === y.product.id).length === 0 &&
        y.quantity !== 0
      ) {
        local.push({
          product_id: y.product.id,
          product_name: y.product.name,
          cover_image: y.product.cover_image,
          package_types: [
            {
              id: y.id,
              type: y.package_type,
              title: y.package_title,
              quantity: y.quantity,
              eta: y.eta,
              price: y.price,
            },
          ],
        });
      } else {
        if (y.quantity !== 0) {
          local
            .find((copy) => copy.product_id === y.product.id)
            .package_types.push({
              id: y.id,
              type: y.package_type,
              title: y.package_title,
              quantity: y.quantity,
              eta: y.eta,
              price: y.price,
            });
        }
      }
      setOrderItems(local);
    });
  };

  const getItems = () => {
    return orderItems.map((item) => (
      <div
        className="md:grid md:grid-cols-[1fr_4fr]"
        key={"order_item" + item.id + Math.random()}
      >
        <div className="flex items-center justify-center">
          <img src={item.cover_image} className="max-h-[150px] py-4" alt="" />
        </div>

        <div className="flex flex-col gap-5 w-full  pb-5 border-b-2 ">
          <div className="uppercase font-bold text-lg mt-5">
            {item.product_name}
          </div>
          {item.package_types.map((pack) => (
            <div
              key={"item_" + item.id + Math.random()}
              className="grid grid-cols-[2fr_1fr_1fr]"
            >
              <div className="font-bold uppercase">{pack.title}</div>
              <div className="font-bold">{pack.quantity}</div>
              <div className="font-bold text-right">
                ${(pack.price * pack.quantity).toLocaleString("en-US")}
              </div>
            </div>
          ))}
        </div>
      </div>
    ));
  };

  const getStatus = (status) => {
    switch (status) {
      case "Order in Cart, not complete":
        return (
          <div className="text-[#FD880F] flex items-center gap-2">
            <IconPencil />
            Order in Cart, not complete
          </div>
        );
      case "Order received and work in progress":
        return (
          <div className="text-[#00BBD4] flex items-center gap-2">
            <IconPackageChecked />
            Order received and work in progress
          </div>
        );
      case "Order shipped":
        return (
          <div className="text-[#9E1FCD] flex items-center gap-2">
            <IconTrack />
            Order shipped
          </div>
        );
      case "Order cancelled":
        return (
          <div className="text-[#EA3523] flex items-center gap-2">
            <IconBlock />
            Order cancelled{" "}
          </div>
        );
      case "Order delivered":
        return (
          <div className="text-[#56B95F] flex items-center gap-2">
            <IconCheckOutline />
            Order delivered
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div key={"order_" + props.order.order_id + Math.random()}>
      <div
        className={`${
          isOpen ? "flex" : "block md:flex"
        } justify-between border-b py-5`}
      >
        <div className="flex flex-col pb-5 md:pb-0">
          <div className="uppercase font-bold text-lg">
            order no. {props.order.order_id}
          </div>
          <div className="text-base uppercase font-bold py-1 md:py-0">
            {getStatus(props.order.order_status)}
          </div>
          <div className="text-base text-gray-500">
            {getOrderDate()} - ${props.order.total.toLocaleString("en-US")}
          </div>
        </div>
        {!isOpen ? (
          <Button
            class="ghost-sm"
            text="see details"
            className="!w-full md:!w-40"
            click={() => {
              setIsOpen(true);
            }}
          />
        ) : (
          <IconArrowUp
            fill="none"
            strokeWidth={2}
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-6 w-6 cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
          />
        )}
      </div>
      {isOpen ? (
        <>
          <div className="md:grid md:grid-cols-2 text-left border-b-2 pb-5 md:pb-20 mt-4">
            <div className="flex flex-col pb-10 md:pb-0">
              <h3 className="uppercase font-bold text-lg mb-4">
                delivery address
              </h3>
              <div className="flex flex-col text-left">
                <strong className="mb-4">
                  {" "}
                  {order.delivery_address.first_name}{" "}
                  {order.delivery_address.last_name}
                </strong>
                <div>{order.delivery_address.company_name}</div>
                <div>
                  {order.delivery_address.address}{" "}
                  {order.delivery_address.address2}
                </div>
                <div>
                  {order.delivery_address.city}, {order.delivery_address.state}{" "}
                  {order.delivery_address.zipcode}
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <h3 className="uppercase font-bold text-lg mb-4">
                Order information
              </h3>
              <div className="flex flex-col text-left">
                <div className="uppercase">order date: {getOrderDate()}</div>
                <div className="uppercase">
                  order number: <strong>{order.order_id}</strong>
                </div>
                <div className="uppercase">
                  estimated delivery: {getDeliveryDate()}
                </div>

                <div className="uppercase">delivery mode: fedex</div>
                <div className="uppercase">
                  tracking number: {order.tracking_number}
                </div>
                <div className="mt-10 flex md:block">
                  <a
                    target="_blank"
                    download={true}
                    rel="noreferrer"
                    href={order.file_url}
                    className={
                      classes[`button`] +
                      " h-10 md:h-12 px-6 py-2 " +
                      classes["ghost"] +
                      " text-center"
                    }
                  >
                    {" "}
                    download to pdf
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <div
              className={
                isActive
                  ? "border-y border-t-black flex justify-between py-5"
                  : "border-b border-gray-300 flex justify-between py-5"
              }
            >
              <button className="accordion-title w-full font-bold">
                <h2 onClick={() => setIsActive(!isActive)}>
                  <div className="flex w-full justify-between">
                    <div className="uppercase text-base font-bold">
                      order details
                    </div>
                    {isActive ? (
                      <IconArrowUp
                        fill="none"
                        strokeWidth={2}
                        viewBox="0 0 24 24"
                        className="h-6 w-6"
                        stroke="currentColor"
                        onClick={() => setIsActive(!isActive)}
                      />
                    ) : (
                      <IconArrowDown
                        fill="none"
                        strokeWidth={2}
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-6 w-6"
                        onClick={() => setIsActive(!isActive)}
                      />
                    )}
                  </div>
                </h2>
              </button>
            </div>

            {isActive ? (
              <div>
                {getItems()}
                <div className="flex flex-col py-5 border-b-2 border-black">
                  <div className="py-5">
                    <div className="flex justify-between">
                      <div className="text-sm">Subtotal:</div>
                      <div className="font-bold">
                        ${order.total.toLocaleString("en-US")}
                      </div>
                    </div>
                    <div className="hidden md:flex justify-between">
                      <div className="text-sm">Estimated Delivery:</div>
                      <div className="">{getDeliveryDate()}</div>
                    </div>
                    <div className="flex md:hidden justify-between">
                      <div className="text-sm">ETA:</div>
                      <div className="">{getDeliveryDateM()}</div>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="text-xl uppercase font-bold">total</div>
                    <div className="font-bold text-xl">
                      ${order.total.toLocaleString("en-US")}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default OrderDetails;
