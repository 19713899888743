import React from 'react';
import Icon from './icon';

function IconBlock(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"{...props}>
      <path
        d="M7 0C8.24193 0 9.42742 0.33871 10.5 0.959677C11.5726 1.58065 12.4194 2.42742 13.0403 3.5C13.6613 4.57258 14 5.75806 14 7C14 8.27016 13.6613 9.42742 13.0403 10.5C12.4194 11.5726 11.5726 12.4476 10.5 13.0685C9.42742 13.6895 8.24193 14 7 14C5.72984 14 4.57258 13.6895 3.5 13.0685C2.42742 12.4476 1.55242 11.5726 0.931452 10.5C0.310484 9.42742 0 8.27016 0 7C0 5.75806 0.310484 4.57258 0.931452 3.5C1.55242 2.42742 2.42742 1.58065 3.5 0.959677C4.57258 0.33871 5.72984 0 7 0ZM10.9798 3.02016C10.3024 2.34274 9.54032 1.89113 8.6371 1.60887C7.73387 1.32661 6.85887 1.27016 5.95565 1.43952C5.05242 1.60887 4.23387 2.00403 3.52823 2.56855L11.4315 10.4718C11.996 9.76613 12.3911 8.94758 12.5605 8.04435C12.7298 7.14113 12.6734 6.26613 12.3911 5.3629C12.1089 4.45968 11.6573 3.69758 10.9798 3.02016ZM3.02016 10.9798C3.66935 11.6573 4.43145 12.1371 5.33468 12.4194C6.2379 12.7016 7.14113 12.7298 8.04435 12.5605C8.94758 12.3911 9.7379 12.0242 10.4718 11.4315L2.56855 3.52823C1.97581 4.2621 1.60887 5.05242 1.43952 5.95565C1.27016 6.85887 1.29839 7.7621 1.58065 8.66532C1.8629 9.56855 2.34274 10.3306 3.02016 10.9798Z"
        fill="currentColor"

      />
    </Icon>
  );
}

export default IconBlock;
