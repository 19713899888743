import axios from "axios";
import { history } from "../libs/browser-history";
import { logger } from "../libs/logger/logger";
import { clearAuth, setAuth } from "../libs/storage/auth";
import { clearCart } from "../store/actions/cart";
import store from "../store/store";

const API_URL = process.env.REACT_APP_PROD_API_URL + "/api/v1/";
const register = (first_name, last_name, email, password) => {
  return axios.post(API_URL + "register", {
    first_name,
    last_name,
    email,
    password,
  });
};
const forget = (email) => {
  return axios.post(API_URL + "forgotten-password", { email });
};
const saveNewPassword = (token, password, password_confirmation) => {
  return axios.post(API_URL + "save-new-password", {
    token,
    password,
    password_confirmation,
  });
};
const login = (email, password, remember) => {
  return axios
    .post(API_URL + "login", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.success !== true) {
        return response.data;
      }
      logger.log(response.data);
      if (response.data.token) {
        setAuth(response.data);
      }
      return response.data;
    });
};
const verifyToken = (token) => {
  return axios
    .get(API_URL, { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => {
      logger.log(res.data);
      this.setState({
        items: res.data /*set response data in items array*/,
        isLoaded: true,
        redirectToReferrer: false,
      });
    });
};

export const logout = () => {
  clearAuth();
  store.dispatch(clearCart);
  history.push("/login");
};

const AuthService = {
  register,
  login,
  logout,
  verifyToken,
  forget,
  saveNewPassword,
};

export default AuthService;
