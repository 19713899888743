// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".product-card_product__card__pGyIA {\n  transition: transform ease 0.75s;\n}\n.product-card_product__card__pGyIA:hover {\n  box-shadow: 0 0 20px 0 #bababa;\n  transform: scale(1.04);\n}\n.product-card_product__card__image__oOtFB {\n  max-width: 100%;\n  max-height: 150px;\n}", "",{"version":3,"sources":["webpack://./src/component/app/product/card/product-card.module.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;AACF;AACE;EACE,8BAAA;EACA,sBAAA;AACJ;AAEE;EACE,eAAA;EACA,iBAAA;AAAJ","sourcesContent":[".product__card {\n  transition: transform ease 0.75s;\n\n  &:hover {\n    box-shadow: 0 0 20px 0 #bababa;\n    transform: scale(1.04);\n  }\n\n  &__image {\n    max-width: 100%;\n    max-height: 150px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"product__card": "product-card_product__card__pGyIA",
	"product__card__image": "product-card_product__card__image__oOtFB"
};
export default ___CSS_LOADER_EXPORT___;
