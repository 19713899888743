import React from "react";
import Header from "../app/header/header";
import GuestRoute from "../global/auth/guestRoute";
import ForgotPassword from "../../Containers/ForgotPassword/ForgotPassword";

function ForgotPasswordPage() {
  return (
    <GuestRoute
      component={
        <>
          <Header />
          <ForgotPassword />
        </>
      }
    />
  );
}

export default ForgotPasswordPage;
