import 'tw-elements';
import './index.css';
import App from './App';
import React from 'react';
import store from './store/store';
import { Provider } from 'react-redux';
import { isDev } from './libs/env-mode';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import { logger } from './libs/logger/logger';

const container = document.getElementById('root');
const root = createRoot(container);

const queryClient = new QueryClient();
logger.info('App started!');

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      {isDev && <ReactQueryDevtools />}
    </QueryClientProvider>
  </Provider>,
);
