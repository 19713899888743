import React from "react";
import { Link } from "react-router-dom";
import { ProductListItemType } from "../types";
import classes from "./product-card.module.scss";

interface Props {
  product: ProductListItemType;
}

function ProductCard(props: Props) {
  const { product } = props;

  return (
    <Link
      state={product.id}
      to={`/products/${product.slug}`}
      className="m-0 my-[15px] sm:m-[15px]"
    >
      <div
        id={`${product.id}`}
        className={
          classes["product__card"] +
          " border-box p-5 bg-white flex flex-col h-full m-2"
        }
      >
        <div className="flex justify-center my-auto">
          <img
            alt={product.name}
            src={product.cover_image}
            className={classes["product-card__image"] + " mb-2 max-h-[150px]"}
          />
        </div>
        <div className="py-10 flex flex-col justify-between h-[100%]">
          <h2 className="my-5 text-base md:text-lg font-bold">
            {product.name}
          </h2>
          <div className={classes[" description"]}>
            <p>{product.variants[0].varianttype.variant.name}</p>
            <p>{product.variants[1].varianttype.variant.name}</p>
            <p>{product.size} {product.uom}</p>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default ProductCard;