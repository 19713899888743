import React from "react";
import Icon from "./icon";

function IconArrowLeftVariant(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}>
      <path
        d="M9.07031 14.8945C9.21094 14.7539 9.28125 14.543 9.28125 14.2969C9.28125 14.0859 9.17578 13.875 9.03516 13.6992L4.81641 9.65625H14.9062C15.1172 9.65625 15.3281 9.58594 15.5039 9.41016C15.6445 9.26953 15.75 9.05859 15.75 8.8125V7.6875C15.75 7.47656 15.6445 7.26562 15.5039 7.08984C15.3281 6.94922 15.1172 6.84375 14.9062 6.84375H4.81641L9.03516 2.80078C9.17578 2.66016 9.28125 2.44922 9.28125 2.20312C9.28125 1.99219 9.21094 1.78125 9.07031 1.60547L8.26172 0.832031C8.08594 0.691406 7.875 0.585938 7.66406 0.585938C7.41797 0.585938 7.20703 0.691406 7.06641 0.832031L0.246094 7.65234C0.0703125 7.82812 0 8.03906 0 8.25C0 8.49609 0.0703125 8.70703 0.246094 8.84766L7.06641 15.668C7.20703 15.8438 7.41797 15.9141 7.66406 15.9141C7.875 15.9141 8.08594 15.8438 8.26172 15.668L9.07031 14.8945Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default IconArrowLeftVariant;
