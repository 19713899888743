// GET
export interface CartType {
    id: number
    total: number
    items: CartPackageType[]
    estimated_delivery: string
}

export interface CartPackageType {
    id: number
    eta: number
    price: number
    quantity: number
    package_type: string
    package_title: string
    product: CartProductType
}

export interface CartProductType {
    id: number,
    name: string,
    cover_image: string,
    prices: CartProductPricesType[]
}

export interface CartProductPricesType {
    slug: string,
    title: string,
    tiers: CartProductPricesTiersType[]
}

export interface CartProductPricesTiersType {
    min: number,
    max: number,
    eta: string,
    price: string
}

// POST
export interface CartAddType {
    cart_items: CartAddItemType[]
}

export interface CartAddItemType {
    product_id: number
    cover_image: string
    product_name: string
    package_types: CartAddItemPackageType[]
}

export interface CartAddItemPackageType {
    type: string
    quantity: number
}

export interface UICartPackageType {
    id: number,
    eta: number,
    type: string,
    price: number,
    title: string,
    quantity: number,
    tiers: CartProductPricesTiersType[]
}

export interface UICartType {
    product_id: number,
    product_name: string,
    cover_image: string,
    package_types: UICartPackageType[],
}

export function mapUICartItems(cart: CartType): UICartType[] {
    const result: UICartType[] = []

    cart.items.forEach(cartItem => {
        const index = result.findIndex(product => product.product_id === cartItem.product.id)
        if (index < 0) {
            result.push({
                product_id: cartItem.product.id,
                product_name: cartItem.product.name,
                cover_image: cartItem.product.cover_image,
                package_types: [
                    {
                        id: cartItem.id,
                        eta: cartItem.eta,
                        price: cartItem.price,
                        quantity: cartItem.quantity,
                        type: cartItem.package_type,
                        title: cartItem.package_title,
                        tiers: cartItem?.product?.prices?.find((price) => price?.slug === cartItem.package_type)?.tiers || [],
                    }
                ],
            })
        } else {
            result[index].package_types.push({
                id: cartItem.id,
                eta: cartItem.eta,
                price: cartItem.price,
                quantity: cartItem.quantity,
                type: cartItem.package_type,
                title: cartItem.package_title,
                tiers: cartItem?.product?.prices?.find((price) => price?.slug === cartItem.package_type)?.tiers || [],
            })
        }
    })

    return result
}

export function mapCartAdd(cart: CartType): CartAddType {
    const result: CartAddType = { cart_items: [] }

    cart.items.forEach(cartItem => {
        const index = result.cart_items.findIndex(product => product.product_id === cartItem.product.id)
        if (index < 0) {
            result.cart_items.push({
                cover_image: cartItem.product.cover_image,
                product_name: cartItem.product.name,
                product_id: cartItem.product.id,
                package_types: [
                    {
                        quantity: cartItem.quantity,
                        type: cartItem.package_type,
                    }
                ],
            })
        } else {
            result.cart_items[index].package_types.push({
                quantity: cartItem.quantity,
                type: cartItem.package_type,
            })
        }
    })

    return result
}

// Redux State 
export interface CartStateType {
    cart: CartType,
    uiCartItemsCount: number,
    uiCartItems: UICartType[]
}